module.exports = [
  {
    name: "Dr Olatujoye Oluwatosin",
    position: "Group Chief Executive Officer",
    member: "Zylus Group International",
    image: require("../image/Team1.png"),
    profile1:
      "Dr. Oluwatosin Olatujoye is a highly esteemed entrepreneur, businessman, and humanitarian with over two decades of experience in various fields. His expertise in sales and marketing, negotiation, public speaking, management, human resources, project management, and development is highly recognized in the industry and he is estimated to have a net worth of $350 Million Dollars, a testament to his success in the business world.",
    profile2:
      "Throughout his career, Dr. Olatujoye has demonstrated exceptional leadership skills, commitment to social responsibility and an unwavering dedication to promoting ethical practices and has been a role model for others in his field.",
    profile3:
      "Dr. Olatujoye's career journey began in 2007 when he joined the American International Insurance Company (AIICO) as a sales representative. He quickly became known for his dedication and commitment to his work, which led to his promotion to branch manager within a few years. Over the course of his 13-year tenure at AIICO, Dr. Olatujoye honed his skills in sales and marketing, management, and negotiation, and gained invaluable experience in the insurance industry. During his time at AIICO, Dr. Olatujoye demonstrated exceptional leadership skills and a deep understanding of the insurance industry. He was instrumental in developing and implementing successful sales and marketing strategies that helped the company achieve significant growth and success. His ability to negotiate complex deals and manage large teams made him a valuable asset to the company. Overall, Dr. Olatujoye's career at AIICO was marked by outstanding achievements and a deep commitment to excellence which make him a highly respected and sought-after professional in the insurance industry.",
    profile4:
      "As an astute entrepreneur, Dr. Olatujoye is always looking for ways to solve problems and create value. He recognized the housing deficit in Nigeria and set out to find solutions to address it. He conducted extensive research on the real estate industry in Africa and the world and founded Oga for Property Investment Ltd. He started with just N500,000 and was able to build an impressive real estate business worth over $200 million in Nigeria. The company was later rebranded to Zylus Homes and Property as it expanded and grew to become a leading player in the Nigerian real estate market. Dr. Olatujoye's success in the real estate industry is due to his keen eye for opportunities and his ability to turn challenges into opportunities. He has been able to identify gaps in the market and provide innovative solutions to meet the needs of his clients. His dedication to excellence and his commitment to providing quality service have earned him the trust and loyalty of his clients.",
    profile5:
      "Dr. Olatujoye has also invested in his education, obtaining numerous qualifications from prestigious institutions. He completed Financing The Entrepreneur Business at the London Business School, learned the fundamental and comprehensive aspects of real estate at the School of Estate, and earned a certificate in entrepreneurship from the Metropolitan School of Business and Management in the UK. He is a fellow of the Institute of Credit Administration and has attended and participated in numerous local and international seminars and summits to broaden his knowledge and skills.",
    profile6:
      "Dr. Olatujoye is a member of several professional bodies, including the Project Management Professional Institute (PMPI) and the International Strategic Management Institute (ISMI)-UK. He has also acquired several certifications, including a Professional Post-graduate certificate in Real Estate & Asset Valuation Management, Proficiency in Human Resources Management, Certified International Strategic Management (CISM), Proficiency in International Strategic Management (PISM), and Certificate in Entrepreneur Management (CEM) from the Enterprise Development Center, PAN Atlantic University, and Lagos Business School.",
    profile7:
      "As a humanitarian, Dr. Olatujoye has a keen interest in giving back to society, raising help for the needy, and youth capacity development. Under his leadership, Zylus Homes carried out several Community Social Responsibility initiatives during the first wave of the COVID-19 pandemic. These initiatives were aimed at assisting those were most affected by the pandemic and alleviating the challenges faced by vulnerable individuals and communities. Through his foundation, the Tosin Olatujoye Foundation, he has contributed to feeding over 1000 less privileged people in Abeokuta, Lagos, and Oyo respectively.",
    profile8:
      "Dr Olatujoye’s philanthropic efforts extend beyond just feeding the less privileged. He is also deeply invested in youth capacity development and has dedicated his time and resources to providing opportunities for young people to succeed. Dr Olatujoye’s commitment to giving back to the society has earned him numerous accolades and recognition including the Icon of Humanity Award.",
    profile9:
      "Dr. Olatujoye's contribution to the real estate industry and the society at large has not gone unnoticed. He has received numerous awards and recognitions for his outstanding work, including AIICO's Best Unit Manager (2016), AIICO Top 10 Agency Manager in Revenue (2017), Nigeria Achievers Award (2020), Top 50 Entrepreneurs in Nigeria, and Nigeria Property Merit Awards. In recognition of his contribution to the industry within a limited time, Dr. Oluwatosin was recently recognized with the Under40Ceo Award.",
    profile10:
      "Dr. Oluwatosin is a distinguished professional with an impressive track record, holding esteemed positions in various renowned organizations. He serves as the Executive Director of Trustline Capital, the Board Chairman of Real Estate Cashback, Travels and Tours, and also holds the prestigious position of GCEO at Zylus Homes & Properties. Furthermore, he is the esteemed founder of Zylus Group International and a co-founder of the esteemed Tosin Olatujoye Foundation.",
    profile11:
      "Dr. Oluwatosin is a distinguished professional with an impressive track record, holding esteemed positions in various renowned organizations. He serves as the Founder/Group Chief Executive Director, Zylus Group International and Executive Director, Trustline Capital, Real Estate Cashback, Zylus Travels and Tours, and Zylus Homes & Property Ltd.Furthermore, he is the esteemed founder of Tosin Olatujoye Foundation - TOF.",
    profile12:
      "He is a shining example of what can be achieved with the right mindset and willingness to take risks and a proof that with hardwork, determination and a clear vision, anything is possible.",
  },
  {
    name: "Bukola Olatujoye",
    position: "Chief Executive Officer",
    member: "Zylus Homes & Properties",
    image: require("../image/Team2.jpg"),
    profile1:
      "Bukola Olatujoye is a seasoned and experienced business administrator and entrepreneur with over a decade of practical experience.",
    profile2:
      "She is a graduate of Business Administration from Lagos State University(LASU).She had served as a senior member of top organizations.",
    profile3:
      "Bukola Olatujoye is certified Human Resources Management Professional.Currently, she serves at the helm of affairs of the management team at Zylus, she has helped steer the organization's affairs towards greater heights and achieve success, which is evident in the company's revenue within the last two years.",
    profile4:
      "At the helm of management affairs in Zylus where she currently occupy the position of the Chief Executive Officer, Mrs Olatujoye was recently recognized and honour by the Nigeria Achievers Award as the Distinguished Real Estate Practitioner of year 2021.",
    profile5: "",
    profile6: "",
    profile7: "",
    profile8: "",
    profile9: "",
    profile10: "",
    profile11: "",
    profile12: "",
  },
  {
    name: "Ogunkeye Ayobami Olakunle",
    position: "ED, Retail & Corporate Services",
    member: "Zylus Homes & Properties",
    image: require("../image/olakunle.jpeg"),
    profile1:
      "Ayobami holds a Bachelor of Science (B.sc) from the University of Ibadan. A fellow of the Institute of Direct Marketing Association of Nigeria (IDMN) and obtained a Post Graduate Executive Diploma from the University of Lagos. He is an Associate of the Chartered Insurance Institute of Nigeria (ACII). A well-grounded Industry veteran with specifics in Business Development, Retail Strategic Sales, Partnerships and collaborations.",
    profile2:
      "A pioneer of several initiatives within the Insurance Industry which has consistently made him to consult for CIIN, NIA, NCRIB and other trade bodies within the Insurance Industry. He is extremely passionate about creating non-conventional channels for sales.",
    profile3:
      "Ayo now serve as the Executive Director Retail & Corporate Services of the Zylus Group International where he oversees the Group Operations and Retail Businesses.",
    profile4:
      "He is a motivational speaker and has attended several courses, seminars, and workshop both locally and internationally. He is happily married and blessed with Children.",
    profile5: "",
    profile6: "",
    profile7: "",
    profile8: "",
    profile9: "",
    profile10: "",
    profile11: "",
    profile12: "",
  },
  {
    name: "Lateef Lawal",
    position: "ED, Zylus Homes & Properties",
    member: "Executive Director",
    image: require("../image/Team3.jpg"),
    profile1:
      "Lateef Started his career in the banking sector where he held the position of a Customer service.",
    profile2:
      " During his stay in Stanbic IBTC Bank he was able manage thousands of diversed customers.",
    profile3:
      "While in the Bank he also shuffle the role of Personal Banking Role which strictly for sales of accounts opening.",
    profile4:
      "After some few years in Banking industry he transited to Oil and Gas sector as a Station Manager.",
    profile5:
      "Lateef exhibits his Human relations Skills coupled with his sales and Marketing experience in the bank.",
    profile6:
      "He learned procurement and finance of petroleum products. After some fews years in the Oil and Gas industry he moved to the Real estate industry.",
    profile7:
      "He joined a startup real estate company called OGA FOR PROPERTY INVESTMENT LTD now Zylus Group International.Upon joining the company he fully displayed all his experiences over the years and rose from being a HR officer to Executive Director Human Capital, Corporate Services and Real Estate. He has managed over 1000 stakeholders and staff members prior to joining Zylus Group International(OGA FOR PROPERTY INVESTMENT LTD).",
    profile8:
      "He joined the company with little or no Real Estate Consultant and as we speak he is managing over 4000 Real Estate Consultant(Realtors) and invariably 65 staff members. Lateef Lawal holds a Bachelor of Science Degree in Industrial Relations and Personnel Management from Lagos State University, Ojo Lagos State.Lateef is currently a MASTERS OF BUSINESS ADMINISTRATION(MBA- HRM) student of BABCOCK UNIVERSITY, Ilishan Remo, ogun State. He is a Member of the Nigerian Institute of Management(Chartered) as well as an Full Member, Chartered Institute of Human Resources Management Nigeria(CIHRMN) He has Profiency Certificate in Management.He is also bagged a Post Graduate Diploma in International Digital Marketing College UK.He is a Certified Sales and Marketing Analyst and also an Associate Member Digital and Social Media Marketing from the International Digital Marketing College UK.",
    profile9:
      "Lateef has also attended Leadership and Strategy classes with Popular Pastor Niyi Adesanya, Northcourt and the Billionaire Conclave(Pastor Olumide Emmanuel) Mr.Lawal L O.PGD.DM, MCIHRM, AIDMC, UK; CPM, CSMA, MBA(in view)",
    profile10: "",
    profile11: "",
    profile12: "",
  },
  {
    name: "Wasiu Sonekan",
    position: "ED, Finance",
    member: "Zylus Group International",
    image: require("../image/Team4.png"),
    profile1:
      "Wasiu Sonekan is a highly motivated individual, an accomplished, versatile, and result-driven professional with a proven track record in accounting and office administration, ultimately benefitting the organization's bottom line. Aside from his position in the cooperative, He is the Managing Director of Zylus Investment and since he joined the organization, he has pushed the Investment arm to achieve giant strides.",
    profile2:
      "He is a graduate of Accounting from the Federal University of Agriculture, Abeokuta(FUNAAB) Ogun State, Nigeria, and an Associate member of the Chartered Institute of Management Accountants of Nigeria.",
    profile3: "",
    profile4: "",
    profile5: "",
    profile6: "",
    profile7: "",
    profile8: "",
    profile9: "",
    profile10: "",
    profile11: "",
    profile12: "",
  },
  {
    name: "Olugbenga Ojo",
    position: "GMD/COO",
    member: "Zylus Group International",
    image: require("../image/Team5.png"),
    profile1:
      "Olugbenga Ojo has over 7 years experience as MD/CEO, 5 years as a General Manager/HBO and other Senior Management positions. ",
    profile2:
      "He is an experienced corporate and business process re- engineer who has demonstrated the ability to lead diverse teams of professionals to new levels of success in a variety of highly competitive industries, cutting - edge markets and fast paced environments.",
    profile3:
      "He possesses a strong technical, professional, and business qualifications with an impressive track record of more than Eighteen(18) years hands - on - field experience in Strategic and Corporate Planning, Business Unit Development, Product and Project Management, Business Process reengineering strategies and growth cum people oriented management and has led very efficient organizations and departments, increasing their market share, profitability, reducing costs and enhancing business operations He holds M.Sc.and MBA degrees in Industrial Chemistry and Marketing respectively, a Miller Heiman Certified Sales Professional in Strategic and Conceptual Selling; Project Management Professional(PMP), Scrum Master Certified, Fellow & Head of Faculty, Chartered Institute of Project Management; Fellow & Head of Faculty, Institute of Customer Relationship Management.A member of NIPR, ScrumStudy™ USA and many other professional bodies.",
    profile4:
      "He was the Managing Director of Rescue Technologies Ltd, Chief Executive Officer of Poise Nigeria, Group Business Director / COO of Chain Reactions Nigeria Ltd, and Chief Executive Officer of Emirates Nigeria 247 Ltd before joining Zylus Elite Cooperative Thrift & Credit Society Ltd as the Vice President.",
    profile5: "",
    profile6: "",
    profile7: "",
    profile8: "",
    profile9: "",
    profile10: "",
    profile11: "",
    profile12: "",
  },
  {
    name: "Komolafe Taiwo",
    position: "Group Head, Account & Finance",
    member: "Zylus Group International",
    image: require("../image/Team9.png"),
    profile1:
      "GROUP HEAD, ACCOUNT AND FINANCE-TAIWO THEOPHILUS KOMOLAFE Taiwo Komolafe is a Professional Accountant with expertise in Financial Reporting, Business Consulting, Analysis, and Corporate Governance.He has over 18 years of post graduation corporate experience having worked with some companies of international reputation and recognition.",
    profile2:
      "As a Certified Accountant, Finance Expert and Business Analyst, he has till date engaged in reviewing and setting up accounting and finance processes and procedures as befitting a corporate organization, and further ensures full and continuous compliance of company's financial statements vis-a-vis operations with best global standard practices, whereby the interests of all stakeholders are well protected in order to avoid capital leakages and assets loss.",
    profile3:
      "Komolafe has headed and supervised various Account Teams and Strategic Business Projects of many corporate organizations, where he ensured achievement of timely and accurate implementation of the strategic goals and objectives of the companies.",
    profile4:
      "He has grown in career to being an Accountant, Finance Manager, CFO, Managing Consultant, and currently the Group Head, Account and Finance of Zylus Group International.",
    profile5:
      "In Zylus Group International, he has been executing high level accounting and financial standards by coordinating the global operations and reporting activities of the company as obtainable in best corporate practices, further leading to the preparation of Financial Statements of the company.",
    profile6:
      "He is a man of integrity whose word always matches his action; an advocate and promoter of due process, sense of ownership, and leakage curbing.His presence and appearance in a team is an addition of confidence for customers, investors and other stakeholders.",
    profile7:
      "His experience over the past years spans through various industries(Accounting and Finance, Real Estate, Fund Procurement, Legal, Immigration, Medical, Gaming Industry, Business Consulting, and Investment Advisory) that invokes his alliance and networking with both local and international clients and vendors.He has been a Business Adviser and a Consultant to various companies.",
    profile8:
      "He graduated from Kogi State University where he obtained a Bachelor degree in Accounting, and emerged among the top- best.",
    profile9:
      "His exceptional excellence makes his lecturers and colleagues call him 'Prof' till date.",
    profile10:
      "KOMOLAFE is a certiﬁed professional member of the Institute of Chartered Accountants of Nigeria(ICAN); Certiﬁed Professional Forensic Accountant[CPFA] of The Institute of Forensic Accountants, USA; Certified Business Analyst, and a member of many other professional bodies, both local and international.He also has to his credit fellowships of other professional bodies, awaiting conferment.",
    profile11: "",
    profile12: "",
  },
  {
    name: "Effiong Micheal",
    position: "Head, Sales & Marketing",
    member: "Zylus Group International",
    image: require("../image/Team19.jpg"),
    profile1:
      "A young man with a great passion for excellence and precision at all times. Also, a very good team player who thrives on working in a team environment, yet is willing to work with little or no supervision, open to any new challenges and opportunities that come his way.",
    profile2:
      "With the experience gained from his past experiences with Aiico Insurance for over fifteen years and Life Action Plus for over two years with excellent performance.",
    profile3:
      "He is more than capable of taking on any challenging tasks required of him and with the drive and zeal to complete it, He is hoping to make a mark of recognition in the industry and bring about positive change wherever he goes.",
    profile4:
      "Currently, he is open to new and better opportunities and learning and gaining more experience. He is a graduate of Economics and an MBA in Marketing from Lagos State University, a Member of the Chartered Institute of Insurance and has many other professional certifications.",
    profile5: "",
    profile6: "",
    profile7: "",
    profile8: "",
    profile9: "",
    profile10: "",
    profile11: "",
    profile12: "",
  },
  {
    name: "James Jolayemi",
    position: "Project Manager",
    member: "Zylus Group International",
    image: require("../image/Team10.png"),
    profile1:
      "James Jolayemi is a trained business development strategist/consultant. Having worked with one of the foremost Venture capitalist companies in Nigeria as a freelancer, he rose to become the Manager of their breakthrough projects.",
    profile2:
      "James has contributed to building notable Fast- Moving Consumer Goods(FMCG), also called consumer packaged goods(CPG) brands in Nigeria among which are; Marmador, Premiercool, Cussons Baby.",
    profile3:
      "His love for building businesses through setting and implementation of strategy has also seen him in working with notable Fintech Companies in Nigeria.",
    profile4:
      "He is an alumnus of University of Ilorin; a Yali ambassador and an experienced strategist.",
    profile5: "",
    profile6: "",
    profile7: "",
    profile8: "",
    profile9: "",
    profile10: "",
    profile11: "",
    profile12: "",
  },
  {
    name: "Ajose Olufemi",
    position: "Head of Operations",
    member: "Zylus Group International",
    image: require("../image/Team20.jpg"),
    profile1:
      "Professional Background: Ajose Olufemi is a seasoned Educator and Manager, bringing over 12 years of comprehensive experience in Management, Administration, and Consulting across diverse sectors. His journey encompasses roles as a Class Teacher, School Administrator, Guest House Manager, Head of School, Country Manager, and currently, he serves as the Subsidiary Manager and Head of Marketing Centres (SMD) at Zylus Group International.",
    profile2:
      "Academic Excellence: A dedicated academic and researcher, Ajose Olufemi holds a Bachelor's degree in Biology Education and a Master's degree in Educational Technology, both from the prestigious Lagos State University. His commitment to education is further evidenced by his certifications as an Oracle Database Administrator, Microsoft Certified in Teaching Sustainable Development Goals, and Google Digital Marketing Skills.",
    profile3:
      "Community Engagement: Ajose is an active member of the TCN Real Estate Community Group, contributing his expertise to the community's growth and development. Beyond his professional commitments, he generously volunteers for various organizations, including The Platform® Nigeria, Governance 101, and Enterprise 500, all affiliated with TCN Global.",
    profile4:
      "Passion for Knowledge Sharing: Driven by a passion for knowledge sharing, Ajose Olufemi embodies the belief that learning is a lifelong journey. His commitment to staying at the forefront of education and technology is evident in both his professional pursuits and community involvement.",
    profile5:
      "Current Endeavors: Presently, Ajose serves as the Subsidiary Manager and Head of Marketing Centres (SMD) at Zylus Group International, where he applies his diverse skill set to drive success and innovation.",
    profile6:
      "Ajose Olufemi's journey is a testament to his multifaceted capabilities, combining educational expertise, managerial acumen, and a commitment to making a positive impact in both professional and community settings. His dedication to continuous learning and knowledge sharing sets him apart in the dynamic landscape of education and management.",
    profile7: "",
    profile8: "",
    profile9: "",
    profile10: "",
    profile11: "",
    profile12: "",
  },
  {
    name: "Olu-Majek Grace",
    position: "Group Head, Customer Service",
    member: "Zylus Group International",
    image: require("../image/Team21.jpg"),
    profile1: "",
    profile2:
      "Grace Olu-Majek is an emphatic and customer- centric professional with progressive years of experience cutting across customer service management, customer growth and analytics, product detailing, customer surveys, innovation and leadership, feedback tracking, etc.",
    profile3:
      "She is a business analyst, who has acquired extensive knowledge in the field through the study of Business Administration and Management at the degree level, and she also earned a good number of customer relations certifications.",
    profile4:
      "Her Customer relationship expertise was enhanced through the opportunity for her to have worked with the Lagos State University, and notable real estate organization(s), where she won several awards for her excellent service delivery.",
    profile5:
      "She is also an alumna of the Lagos Business, school, Lagos state University and the School of Estate, where she badged a real estate brokerage certificate.",
    profile6: "",
    profile7: "",
    profile8: "",
    profile9: "",
    profile10: "",
    profile11: "",
    profile12: "",
  },
  {
    name: "Olatoyinbo Toheeb",
    position: "Head, Digital Marketing",
    member: "Zylus Group International",
    image: require("../image/Team12.png"),
    profile1:
      "Toheeb Olatoyinbo is a Seasoned Head of Digital Marketing known for Driving Results and Growth for Leading Brands. As an accomplished Digital Business Executive and Digital Communications leader with over five years of experience, he has successfully navigated the digital landscape of various industries, including E-commerce, Non-profit organizations, Fin-Tech, Ed-Tech, Entertainment, Real Estate, Transport, and Logistics.",
    profile2:
      "His academic background includes a bachelor’s degree in economics from the University of Ilorin and a Professional Diploma in Digital Marketing, specializing in Growth Hacking and Sales, from the Digital Marketing Skill Institute.",
    profile3:
      "His expertise lies in developing and implementing Digital Marketing Strategies that encompass Content Development, Lead Generation, Conversion Rate Optimization, Social Media Marketing, SEO, and Email Marketing.",
    profile4:
      "With these techniques, he has generated high-quality leads, converted them into loyal customers, retained them and helped organizations establish and maintain brand trust. Currently, he serves as the Head of the Digital Marketing and Online Sales department at Zylus Group International, a leading Real Estate Development firm in Nigeria.",
    profile5:
      "In this role, he is responsible for developing and executing digital marketing strategic plans while utilizing all necessary tools, including Websites, Emails, Social Media, and Blogs, to generate leads, convert and retain clients, and drive long-term revenue growth for the company. In his spare time, he works with individuals and corporate entities to train them on the tenets and techniques of Digital Marketing.",
    profile6:
      "He provides expert guidance on sales funnel development, strategy development and assessment, landing page design, email marketing, online advertising, social media, and Google ads, website development, SEO, content writing, and affiliate marketing.",
    profile7:
      "Overall, his passion for Digital Marketing, coupled with his diverse experience and expertise, make him an asset to any organization looking to establish and maintain a strong online presence.",
    profile8: "",
    profile9: "",
    profile10: "",
    profile11: "",
    profile12: "",
  },
  {
    name: "Eleso Moyosoreoluwa",
    position: "Head Media & Creativity",
    member: "Zylus Group International",
    image: require("../image/Team13.png"),
    profile1:
      "Moyosoreoluwa Eleso-Otefia is a Media Personality, Public Speaker, Content creator and Film Maker passionate about using her voice, platform and contents to tell the stories that matter.",
    profile2:
      "She holds a Bachelor of Arts degree in History & International Studies and a Bachelor of Laws(LL.B), both from Lagos State University as well as a certification in film Production from EbonyLife Creative Academy.",
    profile3:
      "As an on - air personality, voice - over artiste and producer, she was named as one of the top 100 Nigerian OAPs for 2020 and also made top 20 Female OAP Revelations for 2020. She has worked with different media outfits including LASU Radio 95.7FM where she was a radio show host, producer and head, news unit, Delta State broadcasting service, Goodland Radio and UNILAGFM. As a TV Presenter, she has hosted shows on Delta TV and handled online TV production for several media houses.",
    profile4:
      "She currently handles productions for Eniola Badmus TV and she received the TV Personality of the year award from Africa’s Best, Award for Excellence 2022. She is a writer and a corporate MC who has hosted several events successfully under her own events' brand named TalkingMo.",
    profile5:
      "TalkingMo in 2017 also served as a social media platform/vlog where she reaches out to thousands of people on inter-personal, developmental and socio-cultural issues and today, it has moved from an Instagram platform to a YouTube channel in January 2020 where creative contents are dished out. She now has over 7,000 subscribers on YouTube and over 1 million views. With her brand TalkingMo, she won the Nigeria Teens Choice Award 2018 for Choice Female Host / Compere.",
    profile6:
      "She currently heads the media department of a leading real estate company a position she attained in only 6months after joining the organization as a presenter.",
    profile7:
      "Apart from leading the creative in the company, she also creates contents and handles the brand image of the company. Alongside her passion for broadcasting, self - development and mentorship, Moyosoreoluwa founded a platform for media enthusiasts tagged iSpeak where trainings are provided for members and in 2019, she successfully convened the maiden edition of the iSpeak conference tagged ‘Beyond Words’ where hundreds of media enthusiasts gathered for training and mentorship.iSpeak Conference has since 2019 become an annual conference for media enthusiasts and professionals.",
    profile8:
      "She has also mentored a lot of young Nigerians in the act of public speaking with major achievements in Lagos State University. Moyosoreoluwa as a youth advocate has contributed to several community development programs, youth conferences and leadership program.",
    profile9:
      "She is a 2018 Carrington Youth Initiative Fellow, a U.S Consulate Initiative and member of YALI network as well as a onetime Nigeria delegate of One Young World 2014 Youth gathering in Ireland",
    profile10:
      "In line with her social development works, she founded www.volunteerhub.ng in December 2018 to commemorate International Volunteer Day and connect Volunteers to Opportunities. While pursing vigorously a career in media, Moyosoreoluwa hopes to become a household brand in a couple of years.",
    profile11: "",
    profile12: "",
  },
  {
    name: "Makinde Adeyinka",
    position: "Relationship Manager",
    member: "Zylus Group International",
    image: require("../image/Team14.png"),
    profile1: "Not Available",
    profile2: "",
    profile3: "",
    profile4: "",
    profile5: "",
    profile6: "",
    profile7: "",
    profile8: "",
    profile9: "",
    profile10: "",
    profile11: "",
    profile12: "",
  },
  {
    name: "Ashogbon Sceptre",
    position: "Head of Internal Audit, Control and Compliance ",
    member: "Zylus Group International",
    image: require("../image/Team15.png"),
    profile1: "Not Available",
    profile2: "",
    profile3: "",
    profile4: "",
    profile5: "",
    profile6: "",
    profile7: "",
    profile8: "",
    profile9: "",
    profile10: "",
    profile11: "",
    profile12: "",
    profile:
      "Sceptre O. S. Ashogbon BSc, MSc, MCIB, ACILRMN, FCIFCN is a versartile professional with a well honed knowledge and experience in Banking and Finance, Mortgage and Insurance.  He is currently Head, Internal Audit, Compliance and Process Control Department of Zylus Group International. He is equally a part-time lecturer at the University of Ibadan and Heritage Polytechnic Lagos annex. Sceptre has a National Diploma in Accountancy from Lagos State Polytechnic now Lagos State University of Technology. He also has  a Bachelor of Science Degree in Accounting and a Master Degree in Banking and Finance both from the University of Lagos.Prior to joining Zylus Group International Sceptre had worked in First Bank of Nigeria MFB Ltd, Letshego MFB Ltd, Crusader Insurance Company and Jubilee Mortgage Bank.Amongst others, Sceptre has notable award for his 5 years meritorious and outstanding contributions to the progress of Letshego MFB Ltd a leading financial institution domicile in Lagos. Sceptre is a financial expert and a Fellow Chartered Institute of Finance and Control of Nigeria(FCIFCN), Certified Member of the Chartered Institute of Bankers of Nigeria(CIBN), an Associate Member of the Chartered Institute of Loan and Risk Management of Nigeria(ACILRMN) and a Member International Certified Risk Management Professional, United Kingdom(UK).",
  },
  // {
  //   "name": "Barr Amaka Onyeabo",
  //   "position": "Legal Coordinator",
  //   "member": "",
  //   "image": require("../image/Team16.png"),
  //   "profile1": "Amaka is graduate of the prestigious University of Lagos. Called to the Nigerian Bar in 2008.",
  //   "profile2": "She has experience in various areas of the legal practice that has span over 14 years with both law firms and corporate organizations.",
  //   "profile3": "An active and participating member of the Chartered institute of Arbitrators UK. Her experience in banks and other corporate organisation is an added quality to advise and handling of issues for clients; both private and organisations.",
  //   "profile4": "Skilled in debt recovery, alternative dispute resolution and litigation",
  //   "profile5": "",
  //   "profile6": "",
  //   "profile7": "",
  //   "profile8": "",
  //   "profile9": "",
  //   "profile10": "",
  //   "profile11": "",
  //   "profile12": "",
  // },
  {
    name: "Adebayo Victory",
    position: "Group Head, Human Resources",
    member: "Zylus Group International",
    image: require("../image/Team17.png"),
    profile1:
      "Emily Adebayo is a passionate HR Professional with over a decade years of experience in Administration and HR practices.",
    profile2:
      "She has a track record in Insurance, Real Estate,Branding & Advertisement and non-Governmental corporation.",
    profile3:
      "She is a proactive leader with capability of executing HR best practices to align organizational objectives, while maximizing employees potential by fostering a positive work environment that promote employee morale by developing interpersonal relationships within all levels in the organization.",
    profile4:
      "An astute believer in organization culture & principles,this she believes will enhance the organizational productivity and effectiveness.",
    profile5:
      "She studied Sociology at University of Ilorin, Kwara State and also had her Masters(MSc) degree at Lagos State University, Ojo Lagos, where she studied Business Administration and International Relation.She is an Associate Member of Chartered Institute of Personnel Management of Nigeria(CIPM).",
    profile6:
      "She has attended several leadership training both in Nigeria and abroad such as Foundation certification in(WAII) West Africa Insurance Institute Gambia and Certificate of participation in Niyi Adesanya Leadership Training.",
    profile7:
      "She started her career in 2007 as a Senior HR Officer at one of the leading Insurance firm, Industrial and General Insurance Plc(IGI) where she collaborated with others team in recruitment and staff welfare management.",
    profile8:
      "1n 2013, she joined Dalewares Nigeria Ltd where she acted as the Human Resource and Administrative Manager for a period of 5 years.She and her team participated in the recruitment of staff to Daleware Institute of Technology with over 2, 500 employee on board and their management.",
    profile9:
      "In 2019, she joined the Real Estate firm(Numero Group of Company) where she was employed as the HR Manager.Her main responsibility is to ensure the growth and development of the company by recruiting competent talents and ensuring their retention.",
    profile10:
      "Joined Zylus Group International Limited as the Human Resource Manager and was promoted to being the Group HR Manager.",
    profile11:
      "Currently, she is saddled with the responsibility of ensuring that the best hand is recruited into the system and that, they are fully engaged and satisfied with their job deliverable.",
    profile12: "",
  },
  {
    name: "Emakpo Prosper",
    position: "Head, Product Management & Investment Sales",
    member: "Zylus Group International",
    image: require("../image/Team18.png"),
    profile1:
      "Prosper Emakpo is a highly accomplished professional with expertise in Digital Communication Strategy, ICT, and product management.",
    profile2:
      " He has an impressive track record of working with numerous firms across diverse market sectors, consistently delivering outstanding results.",
    profile3:
      "His expertise lies in developing effective corporate strategies and successfully launching products.",
    profile4:
      "Previously, Prosper held the position of Chief Executive Officer at Crib- X Digital Limited and also served as the Founder of XDevice Nigeria, a subsidiary of Crib - X Digital Limited.Under his leadership, the company achieved remarkable success, gaining recognition in the industry.",
    profile5:
      "In 2017, Prosper was honored with a certificate of recommendation and achievement from the Association of African Universities, which took place in Accra, Ghana.Furthermore, in 2018, he was nominated alongside esteemed individuals such as Laura Ikeji, Hustlersquare, and Toolz of Cool FM for the prestigious title of Social Media Business Person of the Year by The Entrepreneur Africa.",
    profile6:
      "Acknowledging his exceptional contributions to the digital landscape, Prosper was bestowed with the title of Most Influential Digital Entrepreneur in 2019 by the NYMIA in Lokoja, Kogi State.",
    profile7:
      "This recognition further solidified his reputation as a leading figure in the industry.",
    profile8:
      "Adding to his achievements, Prosper led his firm to secure the accolade of being among the Top 10 Start - ups of the Year 2021, as recognized by The Entrepreneur Africa.Additionally, during his tenure, XDevice Nigeria experienced exponential growth, with its valuation soaring by over half a billion naira within a mere four months of its launch. In terms of education, Prosper holds an N.C.E in Primary Education from FCET and a B.A in Business Administration from UoPeople, California.",
    profile9:
      "He has also acquired various certifications from esteemed organizations worldwide, further enhancing his professional capabilities.",
    profile10: "",
    profile11: "",
    profile12: "",
  },
];
