import React, { useEffect, useState } from 'react';
import { Form, Col, Row, Button } from 'react-bootstrap';
import contactStyle from "./Contact.module.scss";
import chatting from "../../image/chatting.png";
import place from "../../image/place.png";
import phoneCall from "../../image/phone-call.png";
import callIcon from "../../image/callIcon.png";
import MsgIcon from "../../image/MsgIcon.png";
import SucceesModal from '../modal/success-modal';
import { db } from '../firebase-config';
import { collection, addDoc } from "firebase/firestore";

function Contact(props) {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [massege, setMassege] = useState("");
    const [load, setLoad] = useState(false);
    const [modalShow, setModalShow] = React.useState(false);
    const contactCollectionRef = collection(db, "contact");
    const current = new Date();


    useEffect(() => {
        const ifameData = document.getElementById("iframeId");
        const lat = 6.446533120549917;
        const lon = 3.474505564897151;
        ifameData.src = `https://maps.google.com/maps?q=${lat},${lon}&hl=es;&output=embed`
    })

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoad(true);
        addDoc(contactCollectionRef, {
            fullName: `${firstName} ${lastName}`,
            phone: phone,
            email: email,
            massege: massege,
            date: `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`,
            read: false,
        })
            .then(() => {
                setLoad(false);
                setModalShow(true)
                setFirstName("")
                setLastName("")
                setPhone("")
                setEmail("")
                setMassege("")
            })
            .catch(error => {
                alert("Error occurred", error)
                setLoad(false);
            })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
      <section>
        <div className={contactStyle.holdForm}>
          <div className={contactStyle.layout1}>
            <div>
              <h1>Contact Us</h1>
              <p>Our friendly team would love to hear from you</p>
            </div>
            <Form onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridFirstname">
                  <Form.Label>First name </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="First name "
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridFirst">
                  <Form.Label>Last name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Last name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </Form.Group>
              </Row>

              <Form.Group className="mb-3" controlId="formGridEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formGridAddress2">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Phone Number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
              </Form.Group>

              <Row className="mb-3">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    value={massege}
                    onChange={(e) => setMassege(e.target.value)}
                  />
                </Form.Group>
              </Row>

              <Button
                variant="primary"
                type="submit"
                className={contactStyle.submit}
              >
                {load ? "Please wait..." : "Send Message"}
              </Button>
            </Form>
          </div>
          <div className={contactStyle.map}>
            <iframe id="iframeId" title="Map"></iframe>
          </div>
        </div>
        <div className={contactStyle.holdAddress}>
          <div>
            <h1>Visit Our Offices</h1>
            <p>Find us at these locations</p>
          </div>
          <div>
            <div>
              <h1>Lagos, Nigeria</h1>
              <h1>Head Office</h1>
              <p>
                Head Office: 4A Omon Ebhomenye Street, Off Admiralty Way,
                Lekki Phase 1, Lagos, Nigeria
              </p>
              <p>
                <img src={callIcon} alt="callIcon" /> 07034100972
              </p>
              <p>
                <img src={MsgIcon} alt="MsgIcon" /> info@zylushomes.com
              </p>
            </div>
            <div>
              <h1>Ikoyi Office</h1>
              <p>168, Awolowo Road, Ikoyi, Lagos State.</p>
              <p>
                <img src={callIcon} alt="callIcon" /> 09166961162
              </p>
              <p>
                <img src={MsgIcon} alt="MsgIcon" /> Info@recbcoop.com
              </p>
            </div>
            {/* <div>
              <h1>Mainland Corporate Office I</h1>
              <p>65, Allen Avenue, First Bank Bus Stop, Ikeja Lagos.</p>
            </div> */}
            <div>
              <h1>Enugu Office</h1>
              <p>13, Umuawulu Street, Independence Layout, Enugu.</p>
              <p>
                <img src={callIcon} alt="callIcon" /> 08024691177
              </p>
              <p>
                <img src={MsgIcon} alt="MsgIcon" /> Enuguoffice@zylusgroup.com
              </p>
            </div>
            <div>
              <h1>Abeokuta Office</h1>
              <p>
                3, Lagos-Abeokuta Expressway, Opposite Samdop Filling Station,
                Olomore, Abeokuta, Ogun State
              </p>
              <p>
                <img src={callIcon} alt="callIcon" /> 07061788986, 09152614872
              </p>
              <p>
                <img src={MsgIcon} alt="MsgIcon" /> Zylus.abeokuta@gmail.com
              </p>
            </div>
          </div>
          <div>
            <div>
              <h1>Mainland Corporate Office II</h1>
              <p>
                {/* 51/52 Ogba Ijaye Road, 3rd floor by UBA, Ogba Bus Stop, Lagos. */}
                375, Wempco road, off Agidingbi Ogba Lagos
              </p>
              <p>
                <img src={callIcon} alt="callIcon" /> 09139249705
              </p>
            </div>

            <div>
              <h1>Mainland Corporate Office III</h1>
              <p>
                12/14 Adewunmi Abudu Street, Off Osolo Way, Ajao Estate Lagos
              </p>
              <p>
                <img src={callIcon} alt="callIcon" /> 08136775252
              </p>
            </div>
            <div>
              <h1>Abuja Office</h1>
              <p>Abia House, off Ahmadu Bello way Abuja.</p>
              <p>
                <img src={callIcon} alt="callIcon" />
                {/* 08094658136 */}
                08188563947
              </p>
              {/* 08061555078 */}
              {/* {/* <p><img src={callIcon} alt='callIcon' /> 07061788986, 09152614872</p> */}
              {/* <p><img src={MsgIcon} alt='MsgIcon' /> Zylus.abeokuta@gmail.com</p> */}
            </div>
            {/* <div>
                        <h1>Ibadan Office</h1>
                        <p>
                            A1, Akinyemi Bus Stop, Ring Road, Ibadan
                        </p>
                    </div> */}
          </div>
        </div>
        <div className={contactStyle.holdContact}>
          <div className={contactStyle.cont}>
            <h5>Contact Us</h5>
            <h1>We would love to hear from you</h1>
            <h4>Chat to our friendly team</h4>
          </div>
          <div className={contactStyle.holdChatSell}>
            <div className={contactStyle.holdImageNow}>
              <div className={contactStyle.imageNow}>
                <img src={chatting} alt="http://" />
              </div>
              <div className={contactStyle.imageText2}>
                <p className={contactStyle.Chat}>Chat to sales</p>
                <p className={contactStyle.Speak}>Speak to our friendly team</p>
                <a
                  href="tel:07061032706"
                  target="_blank"
                  className={contactStyle.phone23}
                  rel="noreferrer"
                >
                  <p className={contactStyle.phone}>07061032706</p>
                </a>
              </div>
            </div>
            <div className={contactStyle.holdImageNow}>
              <div className={contactStyle.imageNow}>
                <img src={place} alt="http://" />
              </div>
              <div className={contactStyle.imageText2}>
                <p className={contactStyle.Chat}>Visit Us</p>
                <p className={contactStyle.Speak}>Visit our office HQ</p>
                <p className={contactStyle.phone}>
                  Head Office: 31, Theophilus Oji Street, Lekki Phase 1, Lagos,
                  Nigeria.
                </p>
              </div>
            </div>
            <div className={contactStyle.holdImageNow}>
              <div className={contactStyle.imageNow}>
                <img src={phoneCall} alt="http://" />
              </div>
              <div className={contactStyle.imageText2}>
                <p className={contactStyle.Chat}>Call Us</p>
                <p className={contactStyle.Speak}>Mon- Sat, 8am-5pm</p>
                <a href="tel:07034100972" className={contactStyle.phone23}>
                  <p className={contactStyle.phone}>07034100972</p>
                </a>
                <a
                  href="mailto: Info@zylushomes.com"
                  className={contactStyle.phone23}
                >
                  <p className={contactStyle.phone}>Info@zylushomes.com</p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={contactStyle.holdLine2}>
          <div></div>
        </div>
        <div className={contactStyle.holdNewsletter}>
          <div className={contactStyle.Newsletter}>
            <h1>Subscribe to our Newsletter</h1>
            <h1>Get updates on our products</h1>
          </div>
          <div className={contactStyle.NewsletterSubscribe}>
            <input type="text" placeholder="Your Name" />
            <input type="email" placeholder="Your Email Address" />
            <button>Subscribe</button>
          </div>
        </div>
        <SucceesModal show={modalShow} onHide={() => setModalShow(false)} />
      </section>
    );
}

export default Contact;