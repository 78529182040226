import React, { useState, useEffect } from 'react';
import { Form, Col, Row, Button } from 'react-bootstrap';
import contactStyle from "./Inspection.module.scss";
import chatting from "../../image/chatting.png";
import place from "../../image/place.png";
import phoneCall from "../../image/phone-call.png";
import SucceesModal from '../modal/success-modal';
import { db } from '../firebase-config';
import { collection, addDoc } from "firebase/firestore";
import emailjs from "emailjs-com";

function Inspection(props) {
    const [firstName, setFirstName] = useState("");
    const [realtorPhone, setRealtorPhone] = useState("");
    const [category, setCategory] = useState("");
    const [inspectionTime, setInspectionTime] = useState("");
    const [inspectionDate, setInspectionDate] = useState("");
    const [dayDay, setDayDay] = useState("");
    const [realtorEmail, setRealtorEmail] = useState("");
    const [estatetoVisit, setEstatetoVisit] = useState("");
    const [location, setLocation] = useState("");
    const [massege, setMassege] = useState("");
    const [load, setLoad] = useState(false);
    const [modalShow, setModalShow] = React.useState(false);
    const contactCollectionRef = collection(db, "contact");

    let estate1 = ["Arizon", "Tiwa Commercial Hub", "Hilton City", "Eti-Ile Court"];
    let estate2 = ["Lekki Pride I", "Lekki Pride II", "Lekki Pride III"];
    let estate3 = ["Arizon", "Tiwa Commercial", "Eti-Ile Court"];

    const current = new Date();
    const date = new Date(`${inspectionDate.slice(5, 7)}/${inspectionDate.slice(8, 10)}/${inspectionDate.slice(0, 4)}`);
    const SERVICE_ID = "service_vcx51hg";
    const TEMPLATE_ID = "template_ubvsdvj";
    const USER_ID = "X90B6ufpkeZgIdmHv";

    const weekName = date.toLocaleDateString('en-US', { weekday: 'long' });


    function calende(e) {
        setInspectionDate(e.target.value)
        console.log(estate1.includes(estatetoVisit));
        console.log(weekName === "Monday" || weekName === "Invalid Date");

        if (estate1.includes(estatetoVisit) && weekName === "Monday" || weekName === "Invalid Date") {
            setDayDay("")
            // console.log(estate1.includes(estatetoVisit) && weekName === "Monday");
        } else {
            setDayDay("You can only visit arizon, tiwa commercial hub, hilton city and eti-ile court on monday")
        }
        // if (weekName == "Invalid Date") {
        //     setDayDay("")
        // } else if (estate1.includes(estatetoVisit) && weekName === "Monday") {
        //     setDayDay("You only visit arizon, tiwa commercial hub, hilton city and eti-ile court on monday")
        // } else if (estate2.includes(estatetoVisit) && weekName === "Tuesday" || weekName === "Wednesday") {
        //     setDayDay("You only visit Lekki Pride I, Lekki Pride II and Lekki Pride III on Tuesday")
        // } else if (estate3.includes(estatetoVisit) && weekName === "Thursday") {
        //     setDayDay("You only visit Arizon Tiwa Commercial Eti-Ile Court on Thursday")
        // } else {
        //     setDayDay("")
        // }
    }



    const handleSubmit = (e) => {
        e.preventDefault();
        const templateParams = {
            from_name: firstName,
            to_name: realtorEmail,
            feedback: `Full Name: ${firstName}, \n Realtor Phone: ${realtorPhone},\n Realtor Email: ${realtorEmail}, \n Location: ${location}, \n Category: ${category}, \nInspection Time: ${inspectionTime},\n Inspection Date: ${inspectionDate},\nEstate To Visit: ${estatetoVisit} \nMassege: ${massege},\n Date: ${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`
        };

        emailjs.send(SERVICE_ID, TEMPLATE_ID, templateParams, USER_ID)
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
                setLoad(false);
            });
        setLoad(true);
        addDoc(contactCollectionRef, {
            fullName: firstName,
            RealtorPhone: realtorPhone,
            category: category,
            InspectionTime: inspectionTime,
            inspectionDate: inspectionDate,
            realtorEmail: realtorEmail,
            estatetoVisit: estatetoVisit,
            massege: massege,
            location: location,
            date: `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`,
            read: false,
        })
            .then(() => {
                setLoad(false);
                setModalShow(true);
                setFirstName("")
                setRealtorPhone("")
                setCategory("")
                setInspectionTime("")
                setInspectionDate("")
                setRealtorEmail("")
                setEstatetoVisit("")
                setLocation("")
                setMassege("")
            })
            .catch(error => {
                alert("Error occurred", error)
                setLoad(false);
            })
    }





    useEffect(() => {
        // calende()
        window.scrollTo(0, 0)
    }, [0])

    return (
      <section style={{ marginTop: "100px" }}>
        <h1>{props.name}</h1>
        <div className={contactStyle.holdFormPA}>
          <h3>Book An Inspection</h3>
          <p>Our friendly team would love to hear from you</p>
        </div>
        <div className={contactStyle.holdForm}>
          <div className={contactStyle.layout1}>
            <div>
              <Form onSubmit={handleSubmit}>
                <Form.Group
                  as={Col}
                  className="mb-3"
                  controlId="formGridFirstname"
                >
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Full Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                </Form.Group>

                <Form.Group as={Col} className="mb-3" controlId="formGridFirst">
                  <Form.Label>Realtor Phone Number</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Realtor Phone Number"
                    value={realtorPhone}
                    onChange={(e) => setRealtorPhone(e.target.value)}
                    required
                  />
                </Form.Group>

                <Form.Group as={Col} className="mb-3" controlId="formGridFirst">
                  <Form.Label>Category</Form.Label>
                  <Form.Select
                    aria-label=""
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    required
                  >
                    <option></option>
                    <option value="Realtor Only">Realtor Only</option>
                    <option value="Realtor With Client">
                      Realtor With Client
                    </option>
                  </Form.Select>
                </Form.Group>

                <Form.Group as={Col} className="mb-3" controlId="formGridFirst">
                  <Form.Label>Pick Up Location</Form.Label>
                  <Form.Select
                    aria-label=""
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    required
                  >
                    <option></option>
                    <option value="Iwo Road Roundabout (Ibadan)">
                      Iwo Road Roundabout (Ibadan)
                    </option>
                    <option value="Olomore Abeokuta (Only available for Eti-Ile Court)">
                      Olomore Abeokuta (Only available for Eti-Ile Court)
                    </option>
                    <option value="Gbagada (Main Land)">
                      Gbagada (Main Land)
                    </option>
                    <option value="Iyana Oworo (Main Land)">
                      Iyana Oworo (Main Land)
                    </option>
                    <option value="Iyana Ipaja (Island)">
                      Mega Chicken (Island)
                    </option>
                    <option value="Shoprite (Island)">Shoprite (Island)</option>
                    <option value="Lekki Phase 1 (Island)">
                      Lekki Phase 1 (Island)
                    </option>
                    <option value="Abia House, Off Ahmadu Bello Way">
                      Abia House, Off Ahmadu Bello Way
                    </option>
                    <option value="Treasure Park">Treasure park</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formGridEmail">
                  <Form.Label>Inspection Time</Form.Label>
                  <p>You can only choose </p>
                  <Form.Control
                    type="Time"
                    value="09:00:00"
                    name="timeout[]"
                    onChange={(e) => setInspectionTime(e.target.value)}
                    disabled
                    required
                  />
                </Form.Group>
              </Form>
            </div>
          </div>
          <div className={contactStyle.map}>
            <div>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formGridEmail">
                  <Form.Label>Realtor Email Address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Realtor Email Address"
                    value={realtorEmail}
                    onChange={(e) => setRealtorEmail(e.target.value)}
                    required
                  />
                </Form.Group>

                <Form.Group as={Col} className="mb-3" controlId="formGridFirst">
                  <Form.Label>Estate to Visit</Form.Label>
                  <Form.Select
                    aria-label=""
                    value={estatetoVisit}
                    onChange={(e) => setEstatetoVisit(e.target.value)}
                    required
                  >
                    <option></option>
                    <option value="Tiwa Garden City Phase II">
                      Tiwa Garden City Phase II
                    </option>
                    {/* <option value="Tiwa Garden City II">
                      Tiwa Garden City II
                    </option> */}
                    <option value="Ade Prime Estate">Ade Prime Estate</option>
                    <option value="Eti-Ile Court">Eti-Ile Court</option>
                    <option value="Lekki Pride I">Lekki Pride I</option>
                    <option value="Lekki Pride II">Lekki Pride II</option>
                    <option value="Lekki Pride III">Lekki Pride III</option>
                    {/* <option value="Zylus Court Phase I">Zylus Court Phase I</option> */}
                    {/* <option value="Western Hilltop">Western Hilltop</option> */}
                    <option value="Hilton City">Hilton City</option>
                    <option value="Treasure Park">Treasure Park</option>
                    {/* <option value="Zylus Court Phase II">Zylus Court Phase II</option> */}
                    {/* <option value="Tiwa Garden City">Tiwa Garden City</option> */}
                    <option value="Tiwa Commercial Hub">
                      Tiwa Commercial Hub
                    </option>
                    <option value="Arizon">Arizon</option>
                    {/* <option value="Kapital City Estate">
                      Kapital City Estate
                    </option> */}
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formGridEmail">
                  <Form.Label>Inspection Date</Form.Label>
                  <Form.Control
                    type="Date"
                    value={inspectionDate}
                    onChange={calende}
                    required
                  />
                  {/* <p className={contactStyle.errorText}>{dayDay}</p> */}
                </Form.Group>

                <Row className="mb-3">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      value={massege}
                      onChange={(e) => setMassege(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Row>

                <Button
                  variant="primary"
                  type="submit"
                  className={contactStyle.submit}
                >
                  {load ? "Please wait..." : "Send Message"}
                </Button>
              </Form>
            </div>
          </div>
        </div>

        <div className={contactStyle.holdContact}>
          <div className={contactStyle.cont}>
            <h5>Contact Us</h5>
            <h1>We would love to hear from you</h1>
            <h4>Chat to our friendly team</h4>
          </div>
          <div className={contactStyle.holdChatSell}>
            <div className={contactStyle.holdImageNow}>
              <div className={contactStyle.imageNow}>
                <img src={chatting} alt="http://" />
              </div>
              <div className={contactStyle.imageText2}>
                <p className={contactStyle.Chat}>Chat to sales</p>
                <p className={contactStyle.Speak}>Speak to our friendly team</p>
                <a
                  href="https://wa.me/07061032706"
                  target="_blank"
                  className={contactStyle.phone23}
                  rel="noreferrer"
                >
                  <p className={contactStyle.phone}>Info@zylushomes.com</p>
                </a>
              </div>
            </div>
            <div className={contactStyle.holdImageNow}>
              <div className={contactStyle.imageNow}>
                <img src={place} alt="http://" />
              </div>
              <div className={contactStyle.imageText2}>
                <p className={contactStyle.Chat}>Visit Us</p>
                <p className={contactStyle.Speak}>Visit our office HQ</p>
                <p className={contactStyle.phone}>
                  Head Office: 31, Theophilus Oji Street, Lekki Phase 1, Lagos,
                  Nigeria.
                </p>
              </div>
            </div>
            <div className={contactStyle.holdImageNow}>
              <div className={contactStyle.imageNow}>
                <img src={phoneCall} alt="http://" />
              </div>
              <div className={contactStyle.imageText2}>
                <p className={contactStyle.Chat}>Call Us</p>
                <p className={contactStyle.Speak}>Mon- Sat, 8am-5pm</p>
                <a href="tel:07034100972" className={contactStyle.phone23}>
                  <p className={contactStyle.phone}>07034100972</p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={contactStyle.holdLine2}>
          <div></div>
        </div>
        <div className={contactStyle.holdNewsletter}>
          <div className={contactStyle.Newsletter}>
            <h1>Subscribe to our Newsletter</h1>
            <h1>Get updates on our products</h1>
          </div>
          <div className={contactStyle.NewsletterSubscribe}>
            <input type="text" placeholder="Your Name" />
            <input type="email" placeholder="Your Email Address" />
            <button>Subscribe</button>
          </div>
        </div>
        <SucceesModal show={modalShow} onHide={() => setModalShow(false)} />
      </section>
    );
}

export default Inspection;