import { useState, useEffect } from "react";
import Logo from "../../image/User.png";
import Team1 from "../../image/Team1.png";
import webiner from "../../image/webiner.jpg";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import Ellipse1 from "../../image/TestMO1.png";
import Ellipse2 from "../../image/TestMO2.png";
import Ellipse3 from "../../image/TestMO3.png";
import style from "./index.module.scss";
import { IoMdClose } from "react-icons/io";
import axios from "axios";

export default function Webiner() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [popup, setPopup] = useState(true);
  const [load, setLoad] = useState(false);
  const [success, setSuccess] = useState(false);

  const [day, setDay] = useState("");
  const [hours, setHours] = useState("");
  const [min, setMin] = useState("");
  const [sec, setSec] = useState("");
  const [month, setMonth] = useState("");

  const handleModal = () => {
    setPopup(false);
  };

  const formatTimeUnit = (unit) => {
    return unit.toString().padStart(2, "0");
  };

  const countDown = () => {
    const date = new Date();
    let month = date.getMonth();
    let day = 29 - date.getDate();
    let hour = 18 - date.getHours();
    let min = 60 - date.getMinutes();
    let sec = 60 - date.getSeconds();
    setMonth(month);
    setDay(day);
    setHours(hour);
    setMin(min);
    setSec(sec);
  };

  setInterval(countDown, 1000);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoad(true);
    const dataNow = {
      email: email,
      ip_address: name,
      opted_in_at: phoneNumber,
      optin_ip: message,
    };
    axios
      .post(`${process.env.REACT_APP_baseURL}subscriber`, dataNow, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setLoad(false);
        setSuccess(true);
        setPopup(false);
        console.log(response.data);
        console.log(dataNow);
        setName("");
        setEmail("");
        setPhoneNumber("");
        setMessage("");
      })
      .catch((error) => {
        setLoad(false);
        console.error(error);
        setPopup(false);
      });
  };

  return (
    <section>
      <div className={style.header}>
        <img src={Logo} alt="Not Available" />
        <a href="tel:09155063486">Call us</a>
      </div>
      <div className={style.content}>
        <div className={style.holdCountdown}>
          <h1>
            LIVE WEBINAR: 'How to secure the Perfect Property in Lagos without
            making costly mistake'
          </h1>
          <p>
            In this FREE webinar, learn from industry experts at Zylus Homes
            about the essential steps you must take to avoid costly mistakes
            when purchasing property in Lagos.
          </p>
          <div className={style.holdCountDown}>
            <p>
              <span>{formatTimeUnit(day)}</span>
              <span>Days</span>
            </p>
            <p className={style.dot}>:</p>
            <p>
              <span>{formatTimeUnit(hours)}</span>
              <span>Hours</span>
            </p>
            <p className={style.dot}>:</p>
            <p>
              <span>{formatTimeUnit(min)}</span>
              <span>Minutes</span>
            </p>
            <p className={style.dot}>:</p>
            <p>
              <span>{formatTimeUnit(sec)}</span>
              <span>Seconds</span>
            </p>
          </div>
          <h2>Attend for FREE now!</h2>
        </div>
        <div className={style.holdForm} id="form">
          <div className={style.holdFormText}>
            <h1>
              Free ebook for the first 50 signups on 6 Proven Strategies to
              Avoid Real Estate Scams in Nigeria.
            </h1>
          </div>
          <form onSubmit={handleSubmit}>
            <div>
              <label>Full Name</label>
              <input
                type="text"
                placeholder="Full Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div>
              <label>Email Address</label>
              <input
                type="email"
                placeholder="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <label>Phone Number</label>
              <input
                type="number"
                placeholder="Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
            <div>
              <label>Message</label>
              <textarea
                rows={5}
                placeholder="Message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>
            <button type="submit">{load ? "Please wait..." : "Submit"}</button>
          </form>
          {success && (
            <p className={style.success}>
              <span>Successfully submit data</span>
              <IoMdClose onClick={() => setSuccess(false)} />
            </p>
          )}
        </div>
      </div>

      {/* Why Attend This Webinar? */}
      <div className={style.holdAttend}>
        <div>
          <h1>Why Attend This Webinar?</h1>
          <p>
            Navigating the Lagos property market can be overwhelming, with
            numerous challenges that can lead to costly mistakes. Whether you’re
            a first-time buyer or a seasoned investor, this webinar will equip
            you with the knowledge and tools to make informed decisions and
            secure the perfect property without unnecessary stress.
          </p>
          <a href="#form">Reservation</a>
        </div>
        <div>
          <iframe
            className="w-full h-full md:h-[350px]"
            src="https://www.youtube.com/embed/qnRxRuxRW3M?si=6gpWw_3MrvGoEVa4"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          ></iframe>
        </div>
      </div>

      {/* Here's What You'll Learn in the Webinar */}
      <div className={style.holdWebinar}>
        <div className={style.holdWebinar1}>
          <h2>Here's What You'll Learn in the Webinar</h2>
          <div>
            <h1>Understanding the Lagos Property Market</h1>
            <p>
              Get an in-depth look at current market trends and how they affect
              your buying decision.
            </p>
          </div>
          <div>
            <h1>Legal and Regulatory Insights</h1>
            <p>
              Learn the legal requirements and how to ensure your property is
              fully compliant with Lagos state regulations.
            </p>
          </div>
          <div>
            <h1>Financing Your Property Purchase</h1>
            <p>
              Explore various financing options and choose the one that best
              suits your needs.
            </p>
          </div>
          <div>
            <h1>Avoiding Common Pitfalls</h1>
            <p>
              Discover the most common mistakes buyers make and how to avoid
              them to ensure a smooth transaction.
            </p>
          </div>
          <div>
            <h1>Negotiation Strategies</h1>
            <p>
              Learn how to negotiate the best deals and secure your property at
              the right price.
            </p>
          </div>
        </div>
        <div className={style.holdWebinar2}>
          <img src={webiner} alt="Not Available" />
        </div>
      </div>

      {/* Genuine Feedback from Real People! */}
      {/* <div>
                <h1>Genuine Feedback from Real People!</h1>
            </div> */}

      <div className={style.holdTrust}>
        <div>
          <img src={Team1} alt="Not Available" />
        </div>
        <div>
          <h1>Why Trust Zylus Homes ?</h1>
          <p>
            At Zylus Homes, we pride ourselves on being a trusted name in the
            Nigerian real estate market.
          </p>
          <p>
            With years of experience and a proven track record, we are dedicated
            to helping our clients make sound property investments that align
            with their goals and aspirations. Our team of experts is here to
            guide you every step of the way, ensuring a seamless and successful
            property acquisition process.
          </p>
          <a href="#form">Reserve Your Seat</a>
        </div>
      </div>

      <div className={style.holdTakeaways}>
        <div>
          <h1>Key Takeaways</h1>
          <div>
            <p>
              <IoMdCheckmarkCircleOutline />
              How to identify and avoid common property buying mistakes
            </p>
            <p>
              <IoMdCheckmarkCircleOutline />
              Understanding the legal aspects of property ownership in Lagos
            </p>
            <p>
              <IoMdCheckmarkCircleOutline /> Expert tips on financing your
              property purchase
            </p>
            <p>
              <IoMdCheckmarkCircleOutline /> Step-by-step guidance on securing
              the best property deals
            </p>
          </div>
        </div>
        <div>
          <img src={webiner} alt="Not Available" />
        </div>
      </div>

      <div className={style.testimonial}>
        <h1>Testimonial</h1>

        <div className={style.testimonial2}>
          <div className={style.AlabiSamuel}>
            <div className={style.AlabiSamue2}>
              <div>
                <img src={Ellipse1} alt="Ellipse1" className={style.Ellipse1} />
              </div>
              <div className={style.MediaDev}>
                <h1>Mrs Adeola</h1>
                <p className={style.Dev}>Journalist</p>
                <p>
                  I’m very happy and excited to be getting my allocation today
                  in just 1month after completing payment. Zylus homes came
                  through and delivered as promised
                </p>
              </div>
            </div>
          </div>
          <div className={style.AlabiSamuel + "" + style.testOO}>
            <div className={style.AlabiSamue2}>
              <div>
                <img src={Ellipse2} alt="Ellipse2" className={style.Ellipse1} />
              </div>
              <div className={style.MediaDev}>
                <h1>Adebakin Temidayo</h1>
                <p className={style.Dev}>Trader</p>
                <p>
                  It has been amazing doing business with zylus homes unlike
                  other firms that don’t get allocated on time. Zylus has made
                  every process seamless from payment to allocation. Thank you
                  Zylus for making my dream come through within 1 month.
                </p>
              </div>
            </div>
          </div>
          <div className={style.AlabiSamuel}>
            <div className={style.AlabiSamue2}>
              <div>
                <img src={Ellipse3} alt="Ellipse3" className={style.Ellipse1} />
              </div>
              <div className={style.MediaDev}>
                <h1>Pastor Daniel</h1>
                <p className={style.Dev}>Evangelist</p>
                <p>
                  Tiwa is a wonderful place to have a plot in and coming here to
                  see it myself is like a dream come through for everyone who
                  intends to grow and be wealthy. Zylus homes thank you for
                  giving me the opportunity to secure a plot here.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {popup ? (
        <div className={style.popup}>
          <div className={style.popup2}>
            <p>
              <IoMdClose onClick={handleModal} />
            </p>
            <div className={style.holdForm} id="form">
              <div className={style.holdFormText}>
                <h1>
                  Free ebook for the first 50 signups on 6 Proven Strategies to
                  Avoid Real Estate Scams in Nigeria.
                </h1>
              </div>
              <form onSubmit={handleSubmit}>
                <div>
                  <label>Full Name</label>
                  <input
                    type="text"
                    placeholder="Full Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div>
                  <label>Email Address</label>
                  <input
                    type="email"
                    placeholder="Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div>
                  <label>Phone Number</label>
                  <input
                    type="number"
                    placeholder="Phone Number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>
                <div>
                  <label>Message</label>
                  <textarea
                    rows={5}
                    placeholder="Message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                </div>
                <button type="submit">
                  {load ? "Please wait..." : "Submit"}
                </button>
              </form>
            </div>
          </div>
        </div>
      ) : null}
    </section>
  );
}
