import React, { useEffect } from 'react';
import { IoCall } from 'react-icons/io5';
import EM from "./EM.module.scss";

function EMS(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
      <section className={EM.holdALl}>
        <div>
          <div className={EM.holdAllTeamText}>
            <div
              className={EM.holdAllTeamText1}
              data-aos="fade-down"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
            >
              <h5>The Team</h5>
              <h3>Meet the Executive Management at Zylus Homes</h3>
              <p>
                To be the company our customers want us to be, it takes an
                electric group of passionate operators. Meet the Board of
                directors leading the way at Zylus Homes
              </p>
            </div>
          </div>
          <div className={EM.holdAllExecutiveDirectors}>
            <div className={EM.holdAllExecutiveDirectors2}>
              <h4
                data-aos="fade-down"
                data-aos-offset="200"
                data-aos-easing="ease-in-sine"
                data-aos-duration="600"
              >
                Executive Management
              </h4>
              <div className={EM.holdAllProfileKoko}>
                <div
                  className={EM.holdAllProfileKoko2}
                  data-aos="fade-down"
                  data-aos-offset="200"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
                >
                  <div className={EM.holdImage}></div>
                  <div className={EM.holdText}>
                    <div className={EM.holdText2}>
                      <h5>Dr Olatujoye Oluwatosin</h5>
                      <p>Group Chief Executive Officer</p>
                      <button>View Profile</button>
                    </div>
                  </div>
                </div>

                <div
                  className={EM.holdAllProfileKoko2}
                  data-aos="fade-down"
                  data-aos-offset="200"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
                >
                  <div className={EM.holdImage}></div>
                  <div className={EM.holdText}>
                    <div className={EM.holdText2}>
                      <h5>Bukola Adeleke</h5>
                      <p>Admin & Operations</p>
                      <button>View Profile</button>
                    </div>
                  </div>
                </div>

                <div
                  className={EM.holdAllProfileKoko2}
                  data-aos="fade-down"
                  data-aos-offset="200"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
                >
                  <div className={EM.holdImage}></div>
                  <div className={EM.holdText}>
                    <div className={EM.holdText2}>
                      <h5>Ojo Olugbenga</h5>
                      <p>
                        Group Managing Director (Operations & Business
                        Opportunities)
                      </p>
                      <button>View Profile</button>
                    </div>
                  </div>
                </div>
                <div className={EM.holdAllProfileKoko2}>
                  <div className={EM.holdImage}></div>
                  <div className={EM.holdText}>
                    <div className={EM.holdText2}>
                      <h5>Lateef Lawal</h5>
                      <p>Real Estate & Corporate Service</p>
                      <button>View Profile</button>
                    </div>
                  </div>
                </div>
                <div className={EM.holdAllProfileKoko2}>
                  <div className={EM.holdImage}></div>
                  <div className={EM.holdText}>
                    <div className={EM.holdText2}>
                      <h5>Wasiu Sonekan</h5>
                      <p>Finance & Investment</p>
                      <button>View Profile</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={EM.holdAnytime}>
          <div>
            <h1 className={EM.Reach}>You Can Reach Us Anytime</h1>
            <p className={EM.question}>
              Have a question or doubt about our property?
            </p>
          </div>
          <button className={EM.callUs}>
            <IoCall />
            Call us
          </button>
        </div>
      </section>
    );
}

export default EMS;