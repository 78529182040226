import React, { useEffect } from 'react';
import HousesStyle from "./Houses.module.scss";
import { FiSearch } from "react-icons/fi";
import { IoCall } from 'react-icons/io5';
// import Rectangle62 from "../../../image/Rectangle 62.png"
import Rectangle62 from "../../../image/lekkipride1.jpg"
import Rectangle678 from "../../../image/Rectangle 678.png"
import lekii2Me from "../../../image/lekii2Me.jpg"
import pins from "../../../image/pins.png"
import drainage from "../../../image/drainage.png"
import road from "../../../image/road.png"
import cctvCamera from "../../../image/cctv-camera.png"
import fence from "../../../image/fence.png"
import { useNavigate } from 'react-router-dom';

function Houses(props) {
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
      <section className={HousesStyle.holdAll}>
        <div
          className={HousesStyle.starting}
          data-aos="fade-down"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
        >
          <div>
            <h3>Home is the starting place of love, hope and dreams</h3>
            <p>
              Home is what we deliver to you, not just houses because we
              dedicate a lot attention to details and also implement only the
              quality.
            </p>
          </div>
        </div>

        <div
          className={HousesStyle.holdProperty1}
          data-aos="fade-down"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
        >
          <div className={HousesStyle.holdProperty2}>
            <div className={HousesStyle.holdSearch}>
              <input
                type="search"
                placeholder="Search"
                className={HousesStyle.searchKoko}
              />
              <FiSearch className={HousesStyle.searchIcon} />
            </div>
            <div className={HousesStyle.holdSelect}>
              <select>
                <option>Property Type</option>
                <option>Property Type</option>
              </select>
            </div>
            <div className={HousesStyle.holdSelect}>
              <select>
                <option>Price Range</option>
                <option>Property Type</option>
              </select>
            </div>
            <button className={HousesStyle.searchButin}>Search</button>
          </div>
        </div>
        <div className={HousesStyle.holdDrainage}>
          <div className={HousesStyle.holdAllDrainage1}>
            <div
              className={HousesStyle.holdAllDrainage2}
              data-aos="fade-up"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
            >
              <div className={HousesStyle.holdImage}>
                <h4 className={HousesStyle.SalesNo}>Sales ON</h4>
                <img src={Rectangle62} alt="Rectangle62" />
                <div>
                  <h1>Apartments at Lekki Pride 1</h1>
                  <p>
                    <img
                      src={pins}
                      alt="pins"
                      className={HousesStyle.locationPoint}
                    />
                    Abraham Adesanya, Ogombo Road
                  </p>
                </div>
              </div>
              <div className={HousesStyle.holdImageText}>
                <div>
                  <img src={drainage} alt="drainage" />
                  <p>Drainage</p>
                </div>
                <div>
                  <img src={road} alt="road" />
                  <p>Roads</p>
                </div>
                <div>
                  <img src={cctvCamera} alt="cctvCamera" />
                  <p>Surveillance</p>
                </div>
                <div>
                  <img src={fence} alt="fence" />
                  <p>Gated House</p>
                </div>
              </div>
              <div className={HousesStyle.holdImageExploreButin}>
                <button
                  type="button"
                  onClick={() => navigate("/single-houses")}
                >
                  Explore
                </button>
              </div>
            </div>

            <div
              className={HousesStyle.holdAllDrainage2}
              data-aos="fade-up"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
            >
              <div className={HousesStyle.holdImage}>
                <h4 className={HousesStyle.SalesNo}>Sales ON</h4>
                <img src={lekii2Me} alt="Rectangle 678" />
                <div>
                  <h1>Apartments at Lekki Pride 2</h1>
                  <p>
                    <img
                      src={pins}
                      alt="pins"
                      className={HousesStyle.locationPoint}
                    />
                    Lekki - Epe Expressway.
                  </p>
                </div>
              </div>
              <div className={HousesStyle.holdImageText}>
                <div>
                  <img src={drainage} alt="drainage" />
                  <p>Drainage</p>
                </div>
                <div>
                  <img src={road} alt="road" />
                  <p>Roads</p>
                </div>
                <div>
                  <img src={cctvCamera} alt="cctvCamera" />
                  <p>Surveillance</p>
                </div>
                <div>
                  <img src={fence} alt="fence" />
                  <p>Gated House</p>
                </div>
              </div>
              <div className={HousesStyle.holdImageExploreButin}>
                <button
                  type="button"
                  onClick={() => navigate("/single-houses2")}
                >
                  Explore
                </button>
              </div>
            </div>

            <div
              className={HousesStyle.holdAllDrainage2}
              data-aos="fade-up"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
            >
              <div className={HousesStyle.holdImage}>
                <h4 className={HousesStyle.Outofsales}>Out of sales</h4>
                <img src={Rectangle678} alt="Rectangle 678" />
                <div>
                  <h1>Apartments at Lekki Pride 3</h1>
                  <p>
                    <img
                      src={pins}
                      alt="pins"
                      className={HousesStyle.locationPoint}
                    />
                    Abraham Adesanya, Ogombo Road
                  </p>
                </div>
              </div>
              <div className={HousesStyle.holdImageText}>
                <div>
                  <img src={drainage} alt="drainage" />
                  <p>Drainage</p>
                </div>
                <div>
                  <img src={road} alt="road" />
                  <p>Roads</p>
                </div>
                <div>
                  <img src={cctvCamera} alt="cctvCamera" />
                  <p>Surveillance</p>
                </div>
                <div>
                  <img src={fence} alt="fence" />
                  <p>Gated House</p>
                </div>
              </div>
              <div className={HousesStyle.holdImageExploreButin}>
                <button type="button">Explore</button>
              </div>
            </div>
          </div>
        </div>
        <div
          className={HousesStyle.holdAnytime}
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
        >
          <div>
            <h1 className={HousesStyle.Reach}>You Can Reach Us Anytime</h1>
            <p className={HousesStyle.question}>
              Have a question or doubt about our property?
            </p>
          </div>
          <button className={HousesStyle.callUs}>
            <IoCall />
            Call us
          </button>
        </div>
      </section>
    );
}

export default Houses;