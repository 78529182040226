import React, { useState, useEffect } from "react";
import blogStyle from "./Blog1.module.scss";
import arizonDetails10 from "../../../../image/horeImage10.jpg";
import arizonDetails9 from "../../../../image/horeImage9.jpg";
import arizonDetails8 from "../../../../image/horeImage8.jpg";
import arizonDetails7 from "../../../../image/horeImage7.jpg";
import arizonDetails6 from "../../../../image/horeImage6.jpg";
import arizonDetails5 from "../../../../image/horeImage5.jpg";
import arizonDetails4 from "../../../../image/horeImage.jpg";
import arizonDetails3 from "../../../../image/horeImage2.jpg";
import arizonDetails2 from "../../../../image/horeImage3.jpg";
import arizonDetails1 from "../../../../image/horeImage4.jpg";
import HoTH4 from "../../../../image/HoTH4.png";

function Blog5(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className={blogStyle.holdALl}>
      <div>
        <div className={blogStyle.AllCategory}>
          <div className={blogStyle.AllCategory2}>
            <div>
              <div>
                <h3>
                  Zylsu Group International (Ceremony) : Ooni of Ife unveils
                  Model for Zylus New Estate, Arizon Estate at Annual
                  Thanksgiving and Award.
                </h3>
              </div>
              <div>
                <p className={blogStyle.Developtheskills}>
                  Zylus Group International’s quest to continually boost
                  affordable home ownership in Nigeria is set to launch another
                  estate in Eleko, Idera Scheme, Ibeju Lekki Community called
                  Arizon Estate. The estate model was unveiled at the company’s
                  annual thanksgiving and award which was held on Saturday 25th
                  January 2023 by the Ooni of Ife.
                </p>
              </div>
              <div className={blogStyle.HousingPublished}>
                <span className={blogStyle.HousingPublished2}>
                  Entertainment News
                </span>
                <span className={blogStyle.HousingPublished3}>Published</span>
              </div>
              {/* <button className={blogStyle.learnMore}>Read More</button> */}
              <div className={blogStyle.ADMINMARCH}>
                <span>ADMIN. Jan 20, 2024</span>
              </div>
            </div>
          </div>
          <div className={blogStyle.Rectangle123}>
            <img
              src={arizonDetails3}
              alt="Rectangle123"
              style={{ height: "300px" }}
            />
            <img
              src={arizonDetails4}
              alt="Rectangle123"
              style={{ height: "300px" }}
            />
          </div>
        </div>

        <div className={blogStyle.AllCategory}>
          <div className={blogStyle.AllCategory2}>
            <div className={blogStyle.AllIbejuLekki1Pro}>
              <div className={blogStyle.AllIbejuLekki}>
                <p>
                  The Ooni of Ife, Oba Adeyeye Ogunwusi who was the Royal Father
                  of the day at the event joined the management staff of Zylus
                  Group International and other guests in the unveiling of the
                  Model for Arizon Estate describing it as a masterpiece.
                </p>
              </div>
              <div className={blogStyle.Rectangle123}>
                <img
                  src={arizonDetails1}
                  alt="Rectangle123"
                  style={{ height: "300px" }}
                />
                <img
                  src={arizonDetails2}
                  alt="Rectangle123"
                  style={{ height: "300px" }}
                />
              </div>
              <div className={blogStyle.AllIbejuLekki}>
                <p>
                  While speaking at the event, the Ooni of Ife expressed
                  confidence in the company consistently delivering quality
                  homes and encouraged partners and investors to continue to
                  trust the company with their real estate investments. He added
                  that he also invests in the company and continues to put his
                  weight behind the company because he believes in its vision
                  and dreams.
                </p>
              </div>

              <div className={blogStyle.Rectangle123}>
                <img
                  src={arizonDetails9}
                  alt="Rectangle123"
                  style={{ height: "300px" }}
                />
                <img
                  src={arizonDetails10}
                  alt="Rectangle123"
                  style={{ height: "300px" }}
                />
              </div>

              <div className={blogStyle.AllIbejuLekki}>
                <p>
                  The Chairman of Zylus Group International, Dr Oluwatosin
                  Olatujoye revealed that Arizon Estate is an innovative estate
                  set to birth the Biggest Entertainment City in Africa and will
                  change the game in real estate particularly in the Eleko,
                  Idera Scheme, Ibeju Lekki community while leveraging on the
                  various economic investments in the community like the Mini
                  Amusement Park, Cinema House, Estate Mall, Recreational
                  Center, Business Center, Estate Clinic, Nursery/Daycare
                  School, and Estate Guesthouse.
                </p>
              </div>
            </div>

            <div className={blogStyle.Rectangle123}>
              <img
                src={arizonDetails5}
                alt="Rectangle123"
                style={{ height: "300px" }}
              />
              <img
                src={arizonDetails6}
                alt="Rectangle123"
                style={{ height: "300px" }}
              />
            </div>

            <div className={blogStyle.AllIbejuLekki1Pro}>
              <div className={blogStyle.AllIbejuLekki}>
                {/* <h3>Start small:</h3> */}
                <p>
                  Apart from the unveiling of the Arizon Estate Model, the event
                  also witnessed celebration and awards for the company’s
                  Realtors and Partners in 2023. This saw the emergence of Mr
                  Ayeni Joel as the Sales Champion.
                </p>
              </div>
              <div className={blogStyle.Rectangle123}>
                <img
                  src={arizonDetails7}
                  alt="Rectangle123"
                  style={{ height: "300px" }}
                />
                <img
                  src={arizonDetails8}
                  alt="Rectangle123"
                  style={{ height: "300px" }}
                />
              </div>

              <div className={blogStyle.AllIbejuLekki}>
                {/* <h3>Research and analyze deals:</h3> */}
                <p>
                  Also at the event as guest speakers were Dr Olumide Emmanuel
                  who took turns to charge the over 1000 attendees on the
                  various strategies needed to thrive in business in 2023.
                  Nigeria’s finest actors and comedians AY Comedian and Koffi
                  Tha Guru were also present at the event.
                </p>
              </div>

              {/* <div className={blogStyle.AllIbejuLekki}>
                <h3>Build a reliable team:</h3>
                <p>
                  Surround yourself with a team of professionals who can support
                  your real estate ventures. This may include a real estate
                  agent, a property manager, a real estate attorney,
                  contractors, and a mortgage broker. Having a reliable team
                  will help you navigate the challenges of real estate investing
                  while working a full-time job.
                </p>
              </div>

              <div className={blogStyle.AllIbejuLekki}>
                <h3>Leverage technology and automation:</h3>
                <p>
                  Use technology and automation tools to streamline your real
                  estate activities. For example, you can use property
                  management software to handle tenant communication, rent
                  collection, and maintenance requests. Additionally, consider
                  online platforms for marketing and finding tenants.
                </p>
              </div>

              <div className={blogStyle.AllIbejuLekki}>
                <h3>Time management:</h3>
                <p>
                  Effectively managing your time is crucial when investing in
                  real estate alongside a 9-5 job. Set aside specific time slots
                  for real estate activities, such as property research,
                  networking, and property inspections. Prioritize your tasks
                  and use your time efficiently to maximize productivity.
                </p>
              </div>

              <div className={blogStyle.AllIbejuLekki}>
                <h3>Explore financing options:</h3>
                <p>
                  Research different financing options available to you, such as
                  traditional mortgages, private lenders, or partnerships.
                  Evaluate the pros and cons of each option to determine what
                  works best for your situation. Consider working on improving
                  your credit score to qualify for better financing terms.
                </p>
              </div>

              <div className={blogStyle.AllIbejuLekki}>
                <h3>Network and build relationships:</h3>
                <p>
                  Attend real estate investment meetings, join online forums,
                  and network with other investors. Building relationships with
                  experienced investors, lenders, and industry professionals can
                  provide valuable insights, mentorship, and potential
                  investment opportunities.
                </p>
              </div>

              <div className={blogStyle.AllIbejuLekki}>
                <h3>Scale gradually:</h3>
                <p>
                  As you gain experience and confidence, you can gradually scale
                  your real estate portfolio. Reinvest your profits into
                  acquiring more properties or explore other strategies like
                  house hacking, where you live in one unit of a multifamily
                  property and rent out the other units.
                </p>
              </div>

              <div className={blogStyle.AllIbejuLekki}>
                <h3>Scale gradually:</h3>
                <p>
                  Remember, real estate investing requires patience,
                  persistence, and due diligence. It's important to thoroughly
                  research each investment opportunity and make informed
                  decisions. As you gain more experience and grow your
                  portfolio, you may be able to transition from your 9-5 job to
                  full-time real estate investing if that is your long-term
                  goal.
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Blog5;
