import React, { useState, useEffect } from 'react';
import { IoCall } from 'react-icons/io5';
import CertificationsStyle from "./Certifications.module.scss";
import certi1 from "../../image/certi1.png";
import scanScuml from "../../image/scan Scuml.png";
import LagosStateReal from "../../image/LagosStateReal.jpg";
import CAC from "../../image/CAC.jpg";
import { Modal, Button } from 'react-bootstrap';

function Certifications(props) {
    const [num, setNum] = useState(0);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (index) => {
        setNum(index);
        setShow(true);
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
        <section className={CertificationsStyle.holdALl}>
            <div>
                <div className={CertificationsStyle.holdAllTeamText}>
                    <div className={CertificationsStyle.holdAllTeamText1}>
                        <h5>Certifications and License</h5>
                        <p>
                            Here are all Certifications and License for Zylus Homes.
                        </p>
                    </div>
                </div>
                <div className={CertificationsStyle.holdFrenqently}>
                    <div className={CertificationsStyle.holdFrenqently2}>
                        <div className={CertificationsStyle.holdMiniBox}>
                            <div className={CertificationsStyle.miniBoxText}>
                                <div className={CertificationsStyle.miniBoxText2}>
                                    <div>
                                        <p className={CertificationsStyle.LekkiPride1Text}>Special Control Unit Against Money Laundering</p>
                                    </div>
                                    <div>
                                        <button onClick={() => { handleShow(0) }}>View License</button>
                                    </div>
                                </div>
                            </div>
                            <div className={CertificationsStyle.miniBoxImage}>
                                <img src={certi1} alt="Rectangle62" className={CertificationsStyle.miniBoxImage1} />
                            </div>
                        </div>
                        <div className={CertificationsStyle.holdMiniBox}>
                            <div className={CertificationsStyle.miniBoxText}>
                                <div className={CertificationsStyle.miniBoxText2}>
                                    <div>
                                        <p className={CertificationsStyle.LekkiPride1Text}>Lagos State Real Estate Regulatory Authority</p>
                                    </div>
                                    <div>
                                        <button onClick={() => { handleShow(1) }}>View License</button>
                                    </div>
                                </div>
                            </div>
                            <div className={CertificationsStyle.miniBoxImage}>
                                <img src={LagosStateReal} alt="Rectangle62" className={CertificationsStyle.miniBoxImage1} />
                            </div>
                        </div>
                        <div className={CertificationsStyle.holdMiniBox}>
                            <div className={CertificationsStyle.miniBoxText}>
                                <div className={CertificationsStyle.miniBoxText2}>
                                    <div>
                                        <p className={CertificationsStyle.LekkiPride1Text}>Corporate Affairs Commission</p>
                                    </div>
                                    <div>
                                        <button onClick={() => { handleShow(2) }}>View License</button>
                                    </div>
                                </div>
                            </div>
                            <div className={CertificationsStyle.miniBoxImage}>
                                <img src={CAC} alt="Rectangle62" className={CertificationsStyle.miniBoxImage1} />
                            </div>
                        </div>
                        {/* <div className={CertificationsStyle.holdMiniBox}>
                            <div className={CertificationsStyle.miniBoxText}>
                                <div className={CertificationsStyle.miniBoxText2}>
                                    <div>
                                        <p className={CertificationsStyle.LekkiPride1Text}>Special Control Unit Against Money Laundering</p>
                                    </div>
                                    <div>
                                        <button onClick={() => { handleShow(3) }}>View License</button>
                                    </div>
                                </div>
                            </div>
                            <div className={CertificationsStyle.miniBoxImage}>
                                <img src={certi1} alt="Rectangle62" className={CertificationsStyle.miniBoxImage1} />
                            </div>
                        </div>
                        <div className={CertificationsStyle.holdMiniBox}>
                            <div className={CertificationsStyle.miniBoxText}>
                                <div className={CertificationsStyle.miniBoxText2}>
                                    <div>
                                        <p className={CertificationsStyle.LekkiPride1Text}>Special Control Unit Against Money Laundering</p>
                                    </div>
                                    <div>
                                        <button onClick={() => { handleShow(4) }}>View License</button>
                                    </div>
                                </div>
                            </div>
                            <div className={CertificationsStyle.miniBoxImage}>
                                <img src={certi1} alt="Rectangle62" className={CertificationsStyle.miniBoxImage1} />
                            </div>
                        </div>
                        <div className={CertificationsStyle.holdMiniBox}>
                            <div className={CertificationsStyle.miniBoxText}>
                                <div className={CertificationsStyle.miniBoxText2}>
                                    <div>
                                        <p className={CertificationsStyle.LekkiPride1Text}>Special Control Unit Against Money Laundering</p>
                                    </div>
                                    <div>
                                        <button onClick={() => { handleShow(5) }}>View License</button>
                                    </div>
                                </div>
                            </div>
                            <div className={CertificationsStyle.miniBoxImage}>
                                <img src={certi1} alt="Rectangle62" className={CertificationsStyle.miniBoxImage1} />
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className={CertificationsStyle.holdAnytime}>
                    <div>
                        <h1 className={CertificationsStyle.Reach}>You Can Reach Us Anytime</h1>
                        <p className={CertificationsStyle.question}>Have a question or doubt about our property?</p>
                    </div>
                    <button className={CertificationsStyle.callUs}><IoCall />Call us</button>
                </div>
            </div>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Body>
                    <div className={CertificationsStyle.scanScuml}>
                        {num === 0 && <img src={scanScuml} className={CertificationsStyle.scanScumlPro} alt='scanScuml' />}
                        {num === 1 && <img src={LagosStateReal} className={CertificationsStyle.scanScumlPro2} alt='scanScuml' />}
                        {num === 2 && <img src={CAC} className={CertificationsStyle.scanScumlPro2} alt='scanScuml' />}
                        {num === 3 && <img src={scanScuml} alt='scanScuml' />}
                        {num === 4 && <img src={scanScuml} alt='scanScuml' />}
                        {num === 5 && <img src={scanScuml} alt='scanScuml' />}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </section>
    );
}

export default Certifications;