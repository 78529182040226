import React, { useEffect, useState } from 'react';
import Group170 from "../../../../image/courtB.png";
import brochure1 from "../../../../image/brochure 1.png";
import Rectangle105 from "../../../../image/courtSUB.png";
import Rectangle106 from "../../../../image/courtSMA.png";
import Rectangle107 from "../../../../image/courtSMA2.png";
import Rectangle108 from "../../../../image/courtSMA3.png";
import { BsPlayCircle } from 'react-icons/bs';
import { IoCall } from 'react-icons/io5';
import singleLand from "./Land-Houses.module.scss";
import { Form, Row, Col } from 'react-bootstrap';
import { Modal, Button } from 'react-bootstrap';
import { db } from '../../../firebase-config';
import { collection, addDoc } from "firebase/firestore";
import SucceesModal from '../../../modal/success-modal';

function SIngleLand4(props) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [switchs2, setSwitchs2] = useState(0);
    const [modalShow, setModalShow] = useState(false);
    const [load, setLoad] = useState(false);
    const [fullName, setFullName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [social, setSocial] = useState("");
    const [category, setCategory] = useState("");
    const contactCollectionRef = collection(db, "reservations");
    const current = new Date();

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoad(true);
        addDoc(contactCollectionRef, {
            fullName: fullName,
            phone: phone,
            email: email,
            social: social,
            category: category,
            date: `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`,
            read: false,
        })
            .then(() => {
                setLoad(false);
                setModalShow(true)
                setFullName("")
                setPhone("")
                setEmail("")
                setCategory("")
                setSocial("")
            })
            .catch(error => {
                alert("Error occurred", error)
                setLoad(false);
            })
    }

    const [show1, setShow1] = useState(false);

    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const clickMe2 = (index) => {
        setSwitchs2(index);
    }

    useEffect(() => {
        const ifameData = document.getElementById("iframeId")
        const lat = 6.440065356936118;
        const lon = 3.464997996111175;
        ifameData.src = `https://maps.google.com/maps?q=${lat},${lon}&hl=es;&output=embed`
    })

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
      <section className={singleLand.holdALl}>
        <div>
          <div className={singleLand.holdImage}>
            <div className={singleLand.holdImage2}>
              <img src={Group170} alt="ZylusImage" />
              <div className={singleLand.holdPlayButin}>
                <a
                  href="https://drive.google.com/file/d/1KGBY31pG3-9-mPeaRk3TaUTxykcpfdfO/view?usp=share_link"
                  target="_blank"
                  download
                  rel="noreferrer"
                >
                  <button className={singleLand.holdPlayInee}>
                    <img src={brochure1} alt="ZylusImage" />
                    Download Brochure
                  </button>
                </a>
                <button onClick={handleShow}>
                  <BsPlayCircle />
                  Play Video
                </button>
                <a href="" target="_blank" download rel="noreferrer">
                  <button className={singleLand.holdPlayInee}>
                    <img src={brochure1} alt="ZylusImage" />
                    Download FAQ
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div className={singleLand.holdApartment}>
            <div className={singleLand.holdApartment2}>
              <div className={singleLand.holdBedroom2}>
                <img src={Rectangle105} alt="Rectangle31" />
              </div>
              <div className={singleLand.holdBedroom}>
                <h1>Zylus Court Phase II</h1>
                <p className={singleLand.SQM}>500 SQM</p>
                <div>
                  <h4>Location</h4>
                  <p>Opp. Beechwood Estate Bogije, Lagos.</p>
                </div>
                <div>
                  <h4>Proximity</h4>
                  <ul>
                    <li>Beach Wood Estate</li>
                    <li>Amen Estate</li>
                    <li>Lagos Business School</li>
                    <li>GreenSprings Schools</li>
                    <li>Dangote Refinery</li>
                  </ul>
                </div>
                <div>
                  <h4>Overview</h4>
                  <p>
                    Zylus Court phase II is strategically located in an estate
                    that currently housed Over 250 people who are currently
                    living there.Within the next 2 years, Zylus Court will be
                    serving as an abode for an exclusive 500 lovers of serenity,
                    solar activated environment and the biggest Mall area will
                    fully open for operation serving over 50,000 tourists from
                    around the world.
                  </p>
                </div>
              </div>
            </div>
            <div className={singleLand.holdSMallImage}>
              <div className={singleLand.holdSMallImage2}>
                <div>
                  <img src={Rectangle106} alt="Rectangle31" />
                </div>
                <div>
                  <img src={Rectangle107} alt="Rectangle31" />
                </div>
                <div>
                  <img src={Rectangle108} alt="Rectangle31" />
                </div>
              </div>
            </div>
          </div>

          <div className={singleLand.holdViewPaymentPlan}>
            <div className={singleLand.holdViewPaymentPlan2}>
              <h1>Zylus Court Phase II</h1>
              <div className={singleLand.holdViewCityPricing}>
                {/* <div className={singleLand.holdViewCityPricing2}>
                  <div className={singleLand.holdViewCityPricing3}>
                    <h1>Zylus Court II</h1>
                    <p>Land title:(Certificate of Occupancy)</p>
                    <h5>500 Square Meters</h5>
                    <div>
                      <h5>₦18,000,000</h5>
                      <p>Initial price: N2,000,000</p>
                    </div>
                  </div>
                  <div className={singleLand.holdViewPaymentPlanMN}>
                    <button onClick={handleShow1}>View Payment Plan</button>
                  </div>
                </div> */}
                <div className={singleLand.holdViewCityPricing2}>
                  <div className={singleLand.holdViewCityPricing3}>
                    <h1>Zylus Court II</h1>
                    <p>Land title:(Government Allocation)</p>
                    <h5>500 Square Meters</h5>
                    <div>
                      <h5>₦30,000,000</h5>
                      <p>Initial price: N3,000,000</p>
                    </div>
                  </div>
                  <div className={singleLand.holdViewPaymentPlanMN}>
                    <button onClick={handleShow1}>View Payment Plan</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={singleLand.holdLekkiPrideIEstateM}>
            <div className={singleLand.holdLekkiPrideIEstate2M}>
              <h4>Make your Reservation</h4>
              <div className={singleLand.holdLekkiPrideIEstate2M2}>
                <div className={singleLand.holdForm}>
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="formGridAddress1">
                      <Form.Label>Full Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Full Name"
                        style={{ border: "2px solid #ffcc29" }}
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                        required
                      />
                    </Form.Group>
                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter email"
                          style={{ border: "2px solid #ffcc29" }}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </Form.Group>

                      <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Phone Number"
                          style={{ border: "2px solid #ffcc29" }}
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          required
                        />
                      </Form.Group>
                    </Row>

                    <Form.Group
                      as={Col}
                      className="mb-3"
                      controlId="formGridState"
                    >
                      <Form.Label>How did you hear about us?</Form.Label>
                      <Form.Select
                        defaultValue=""
                        style={{ border: "2px solid #ffcc29" }}
                        value={social}
                        onChange={(e) => setSocial(e.target.value)}
                        required
                      >
                        <option></option>
                        <option>Linkedin</option>
                        <option>Instagram</option>
                        <option>Facebook</option>
                        <option>Twitter</option>
                      </Form.Select>
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      className="mb-3"
                      controlId="formGridState"
                    >
                      <Form.Label>Are you working with a Realtor</Form.Label>
                      <Form.Select
                        defaultValue=""
                        style={{ border: "2px solid #ffcc29" }}
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        required
                      >
                        <option></option>
                        <option>Yes</option>
                        <option>No</option>
                      </Form.Select>
                    </Form.Group>

                    <button className={singleLand.formButton}>
                      {load ? "Please wait..." : "Submit"}
                    </button>
                  </Form>
                </div>
                <div className={singleLand.holdMap}>
                  <iframe
                    id="iframeId"
                    title="Map"
                    className={singleLand.mapKoko}
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
          <div className={singleLand.holdAnytime}>
            <div>
              <h1 className={singleLand.Reach}>You Can Reach Us Anytime</h1>
              <p className={singleLand.question}>
                Have a question or doubt about our property?
              </p>
            </div>
            <button className={singleLand.callUs}>
              <IoCall />
              Call us
            </button>
          </div>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h5>Zylus Court Phase II - Estate Launching.</h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <iframe
              width="100%"
              height="300px"
              src="https://www.youtube.com/embed/DHBR6ptKuc0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={show1} onHide={handleClose1} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Payment Plan</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={singleLand.holdAllPriceLand1}>
              <div className={singleLand.holdAllPriceLand2}>
                <div className={singleLand.holdButin}>
                  <button
                    type="button"
                    className={
                      switchs2 === 0 ? singleLand.meter : singleLand.outMeter
                    }
                    onClick={() => {
                      clickMe2(0);
                    }}
                  >
                    500 SQM
                  </button>
                  {/* <button
                    type="button"
                    className={
                      switchs2 === 1 ? singleLand.meter : singleLand.outMeter
                    }
                    onClick={() => {
                      clickMe2(1);
                    }}
                  >
                    417 SQM
                  </button>
                  <button
                    type="button"
                    className={
                      switchs2 === 2 ? singleLand.meter : singleLand.outMeter
                    }
                    onClick={() => {
                      clickMe2(2);
                    }}
                  >
                    300 SQM
                  </button>
                  <button
                    type="button"
                    className={
                      switchs2 === 3 ? singleLand.meter : singleLand.outMeter
                    }
                    onClick={() => {
                      clickMe2(3);
                    }}
                  >
                    250 SQM
                  </button> */}
                </div>
                <div
                  className={
                    switchs2 === 0
                      ? singleLand.display + singleLand.holdListMe2
                      : singleLand.notDisplay
                  }
                  style={{ width: "100%" }}
                >
                  <div className={singleLand.holdTableMeNow}>
                    <table>
                      <thead>
                        <tr>
                          <th>Months</th>
                          <th>Amount</th>
                          <th>Initial Deposit</th>
                          <th>Monthly Payment</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>0 - 3 month</td>
                          <td>N27,000,000</td>
                          <td>N3,000,000</td>
                          <td>N8,333,333</td>
                        </tr>
                        <tr>
                          <td>0 - 4 month</td>
                          <td>N28,000,000</td>
                          <td>N3,000,000</td>
                          <td>N4,333,333</td>
                        </tr>
                        <tr>
                          <td>7 - 12 month</td>
                          <td>N29,000,000</td>
                          <td>N3,000,000</td>
                          <td>N2,250,000</td>
                        </tr>
                        <tr>
                          <td>13 - 18 month</td>
                          <td>N30,000,000</td>
                          <td>N3,000,000</td>
                          <td>N1,555,555</td>
                        </tr>
                        <tr></tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  className={
                    switchs2 === 1
                      ? singleLand.display + singleLand.holdListMe2
                      : singleLand.notDisplay
                  }
                  style={{ width: "100%" }}
                >
                  <div className={singleLand.holdTableMeNow}>
                    <table>
                      <thead>
                        <tr>
                          <th>Months</th>
                          <th>Amount</th>
                          <th>Initial Deposit</th>
                          <th>Monthly Payment</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>0 - 3 month</td>
                          <td>N23,000,000</td>
                          <td>N3,000,000</td>
                          <td>N7,000,000</td>
                        </tr>
                        <tr>
                          <td>0 - 4 month</td>
                          <td>N24,000,000</td>
                          <td>N3,000,000</td>
                          <td>N3,666,666</td>
                        </tr>
                        <tr>
                          <td>7 - 12 month</td>
                          <td>N25,000,000</td>
                          <td>N3,000,000</td>
                          <td>N1,916,666</td>
                        </tr>
                        <tr>
                          <td>13 - 18 month</td>
                          <td>N26,500,000</td>
                          <td>N3,000,000</td>
                          <td>N1,333,333</td>
                        </tr>
                        <tr></tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  className={
                    switchs2 === 2
                      ? singleLand.display + singleLand.holdListMe2
                      : singleLand.notDisplay
                  }
                  style={{ width: "100%" }}
                >
                  <div className={singleLand.holdTableMeNow}>
                    <table>
                      <thead>
                        <tr>
                          <th>Months</th>
                          <th>Amount</th>
                          <th>Initial Deposit</th>
                          <th>Monthly Payment</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>0 - 3 month</td>
                          <td>N17,000,000</td>
                          <td>N3,000,000</td>
                          <td>N5,000,000</td>
                        </tr>
                        <tr>
                          <td>0 - 4 month</td>
                          <td>N18,000,000</td>
                          <td>N3,000,000</td>
                          <td>N2,666,666</td>
                        </tr>
                        <tr>
                          <td>7 - 12 month</td>
                          <td>N19,000,000</td>
                          <td>N3,000,000</td>
                          <td>N1,416,666</td>
                        </tr>
                        <tr>
                          <td>13 - 18 month</td>
                          <td>N20,000,000</td>
                          <td>N3,000,000</td>
                          <td>N1,000,000</td>
                        </tr>
                        <tr></tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  className={
                    switchs2 === 3
                      ? singleLand.display + singleLand.holdListMe2
                      : singleLand.notDisplay
                  }
                  style={{ width: "100%" }}
                >
                  <div className={singleLand.holdTableMeNow}>
                    <table>
                      <thead>
                        <tr>
                          <th>Months</th>
                          <th>Amount</th>
                          <th>Initial Deposit</th>
                          <th>Monthly Payment</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>0 - 3 month</td>
                          <td>N13,500,000</td>
                          <td>N2,000,000</td>
                          <td>N3,833,333</td>
                        </tr>
                        <tr>
                          <td>0 - 4 month</td>
                          <td>N14,500,000</td>
                          <td>N2,000,000</td>
                          <td>N2,083,333</td>
                        </tr>
                        <tr>
                          <td>7 - 12 month</td>
                          <td>N15,500,000</td>
                          <td>N2,000,000</td>
                          <td>N1,125,000</td>
                        </tr>
                        <tr>
                          <td>13 - 18 month</td>
                          <td>N16,500,000</td>
                          <td>N2,000,000</td>
                          <td>N805,555</td>
                        </tr>
                        <tr></tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <SucceesModal show={modalShow} onHide={() => setModalShow(false)} />
      </section>
    );
}

export default SIngleLand4;