import React from 'react';
import ZYLUSNEWLogoFooter from "../../image/ZYLUS NEW Logo Footer.png"
import { Link } from 'react-router-dom';
import { ImFacebook, ImYoutube } from "react-icons/im"
import { AiOutlineInstagram } from "react-icons/ai"
import FooterStyle from "./Footer.module.scss";

function Footer(props) {
    return (
      <section>
        <div
          className={FooterStyle.holdAll}
          data-aos="fade-down"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
        >
          <div className={FooterStyle.Location}>
            <img src={ZYLUSNEWLogoFooter} alt="ZYLUSNEWLogoFooter" />
            <h5>Our Location</h5>
            <p>
              Head Office: 4A Omon Ebhomenye Street, Off Admiralty Way,
              Lekki Phase 1, Lagos, Nigeria.
              <br />
              Mainland Cooperate Office: 12/14 Adewunmi Abdul Street, off Osolo
              way Ajao Estate Lagos.
            </p>
          </div>
          <div className={FooterStyle.AboutTiwa}>
            <ul>
              <h5>About Zylus Homes</h5>
              <li>
                <Link to="/">Real Estate Videos</Link>
              </li>
              <li>
                <Link to="/">Blog</Link>
              </li>
              <li>
                <Link to={"inspection"}>Book an Inspection</Link>
              </li>
              <li>
                <Link to="/">How To Use Zylus Portal</Link>
              </li>
              <li>
                <Link to="/">Purchase</Link>
              </li>
            </ul>
          </div>
          <div className={FooterStyle.Support}>
            <ul>
              <h5>Help & Support</h5>
              <li>
                <Link to="/faq">FAQS</Link>
              </li>
              <li>
                <Link to={"/contact"}>Customer Care</Link>
              </li>
              <li>
                <Link to={"inspection"}>Book an Inspection</Link>
              </li>
              <li>
                <Link to="/">How To Use Zylus Portal</Link>
              </li>
              <li>
                <Link to="/">News</Link>
              </li>
            </ul>
          </div>
          <div className={FooterStyle.Support1}>
            <h5>Follow Us</h5>
            <div>
              <a
                href="https://www.facebook.com/groups/1107458949860758/"
                target="_blank"
                rel="noreferrer"
              >
                <div>
                  <ImFacebook />
                </div>
              </a>
              <a
                href="https://www.instagram.com/zylushomes/"
                target="_blank"
                rel="noreferrer"
                style={{ margin: "0 10px" }}
              >
                <div>
                  <AiOutlineInstagram />
                </div>
              </a>
              <a
                href="https://www.youtube.com/@zylustv6590"
                target="_blank"
                rel="noreferrer"
              >
                <div>
                  <ImYoutube />
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    );
}

export default Footer;
