import React from 'react';
import produceSTyle from "./Products.module.scss";
import Group175 from "../../image/Group 175.png";
import Group172 from "../../image/Group 172.png";
import { Link } from 'react-router-dom';

function Products(props) {
    return (
      <section>
        <div className={produceSTyle.holdLandHouses}>
          <div
            className={produceSTyle.holdLandHouses2}
            data-aos="fade-left"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <img src={Group175} alt="Group 175" />
            <div>
              <h1>
                Take a tour of our beautiful houses and have a feel of the
                quality that we deliver
              </h1>
              <Link to={"/houses"}>Explore Houses</Link>
            </div>
          </div>
          <div
            className={produceSTyle.holdLandHouses2}
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <img src={Group172} alt="Group 175" />
            <div>
              <h1>
                Take a tour of our highly valued lands and have a feel of the
                quality that we deliver
              </h1>
              <Link to={"/lands"}>Explore Lands</Link>
            </div>
          </div>
        </div>
        <div
          className={produceSTyle.holdNewsletter}
          data-aos="fade-down"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
        >
          <div className={produceSTyle.Newsletter}>
            <h1>Subscribe to our Newsletter</h1>
            <h1>Get updates on our products</h1>
          </div>
          <div className={produceSTyle.NewsletterSubscribe}>
            <input type="text" placeholder="Your Name" />
            <input type="email" placeholder="Your Email Address" />
            <button>Subscribe</button>
          </div>
        </div>
      </section>
    );
}

export default Products;