import React, { useEffect, useState } from 'react';
import { BsPlayCircle, BsFillCheckCircleFill } from 'react-icons/bs';
import { BiFile } from 'react-icons/bi';
import singleHouse from "./singleHouses.module.scss";
import homesecurity from "../../../../image/home-security.png";
import drainage from "../../../../image/drainage.png";
import Ho1 from "../../../../image/Ho1.png";
import Ho2 from "../../../../image/Ho2.png";
import Ho3 from "../../../../image/Ho3.png";
import Ho4 from "../../../../image/Ho4.png";
import HoTH2 from "../../../../image/HoTH2.png";
import HoTH3 from "../../../../image/HoTH3.png";
import HoTH4 from "../../../../image/HoTH4.png";
import HoTH5 from "../../../../image/HoTH5.png";
import BED1 from "../../../../image/BED1.png";
import BED2 from "../../../../image/BED2.png";
import BED3 from "../../../../image/BED3.png";
import BED4 from "../../../../image/BED4.png";
import road from "../../../../image/road.png";
import cctvCamera from "../../../../image/cctv-camera.png";
import fence from "../../../../image/fence.png";
import housesImage from "../../../../image/lekkipride1.jpg";
import { Form, Row, Col } from 'react-bootstrap';
import { IoCall } from 'react-icons/io5';
import { Modal, Button } from 'react-bootstrap';
import { db } from '../../../firebase-config';
import { collection, addDoc } from "firebase/firestore";
import SucceesModal from '../../../modal/success-modal';

function SingleHouses(props) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [modalShow, setModalShow] = useState(false);
    const [load, setLoad] = useState(false);
    const [fullName, setFullName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [social, setSocial] = useState("");
    const [category, setCategory] = useState("");
    const contactCollectionRef = collection(db, "house-reservations");
    const current = new Date();

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoad(true);
        addDoc(contactCollectionRef, {
            fullName: fullName,
            phone: phone,
            email: email,
            social: social,
            category: category,
            date: `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`,
            read: false,
        })
            .then(() => {
                setLoad(false);
                setModalShow(true)
                setFullName("")
                setPhone("")
                setEmail("")
                setCategory("")
                setSocial("")
            })
            .catch(error => {
                alert("Error occurred", error)
                setLoad(false);
            })
    }

    useEffect(() => {
        const ifameData = document.getElementById("iframeId")
        const lat = 6.440065356936118;
        const lon = 3.464997996111175;
        ifameData.src = `https://maps.google.com/maps?q=${lat},${lon}&hl=es;&output=embed`
    })

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
      <section className={singleHouse.holdALl}>
        <div>
          <div
            className={singleHouse.holdHouses}
            data-aos="fade-down"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <img src={housesImage} alt="houses" />
            <div className={singleHouse.holdHousesText}>
              <h1>LEKKI PRIDE PHASE 1</h1>
              <p>Abraham Adesanya, Ogombo Road</p>
              <p>(Certificate Of Occupancy)</p>
              <div>
                <button onClick={handleShow}>
                  <BsPlayCircle />
                  Play Video
                </button>
                <button>
                  <BiFile />
                  Brochure
                </button>
              </div>
            </div>
          </div>

          <div
            className={singleHouse.holdProvision}
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <div className={singleHouse.holdProvision2}>
              <div className={singleHouse.blackHeadText}>
                <h1>Lekki Pride 1</h1>
                <p>General Features </p>
              </div>
              <div className={singleHouse.holdImageText}>
                <div>
                  <img src={drainage} alt="drainage" />
                  <p>Drainage</p>
                </div>
                <div>
                  <img src={road} alt="road" />
                  <p>Roads</p>
                </div>
                <div>
                  <img src={cctvCamera} alt="cctvCamera" />
                  <p>Surveillance</p>
                </div>
                <div>
                  <img src={fence} alt="fence" />
                  <p>Gated House</p>
                </div>
                <div>
                  <img src={homesecurity} alt="fence" />
                  <p>Home Security</p>
                </div>
              </div>
            </div>
          </div>

          <div className={singleHouse.holdApartment}>
            <div className={singleHouse.holdApartment2}>
              <div
                className={singleHouse.holdBedroom}
                data-aos="fade-right"
                data-aos-offset="200"
                data-aos-easing="ease-in-sine"
                data-aos-duration="600"
              >
                <h1>
                  <span
                    style={{ width: "100%", padding: "20px" }}
                    className={singleHouse.holdBedroomSPan1}
                  >
                    1 Bedroom Apartment
                  </span>
                  {/* <span className={singleHouse.holdBedroomSPan2}>N62M</span> */}
                </h1>
                <h1> </h1>
                <div>
                  <div>
                    <BsFillCheckCircleFill />
                  </div>
                  <p>
                    Lekki Pride I, 1 room apartment consists of one room and
                    Living room.
                  </p>
                </div>
                <div>
                  <div>
                    <BsFillCheckCircleFill />
                  </div>
                  <p>
                    This gives you benefit to earn rental income from your
                    investment at lekki pride annually after full payment
                    completion or even earn daily, weekly and monthly from
                    shortlets /Airbnb.
                  </p>
                </div>
                <div>
                  <div>
                    <BsFillCheckCircleFill />
                  </div>
                  <p>
                    Lekki Pride is strategically located at Ajiwe Bus Stop, by
                    Abraham Adesanya Roundabout, Lekki-Epe Expressway.
                  </p>
                </div>
                <div>
                  <div>
                    <BsFillCheckCircleFill />
                  </div>
                  <p>
                    Lekki Pride 1 apartment offers spacious units of apartments
                    tailored to meet your highest standards.
                  </p>
                </div>
                <div>
                  <div>
                    <BsFillCheckCircleFill />
                  </div>
                  <p>
                    Abraham Adesanya estate is located after Ajah, off the Lekki
                    – Epe expressway. It is an estimated 30 minutes drive to
                    Victoria Island without traffic.
                  </p>
                </div>
              </div>
              <div
                className={singleHouse.holdBedroom2}
                data-aos="fade-left"
                data-aos-offset="200"
                data-aos-easing="ease-in-sine"
                data-aos-duration="600"
              >
                <img src={Ho1} alt="Rectangle31" />
              </div>
            </div>
            <div className={singleHouse.holdSMallImage}>
              <div className={singleHouse.holdSMallImage2}>
                <div
                  data-aos="fade-right"
                  data-aos-offset="200"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
                >
                  <img src={Ho2} alt="Rectangle31" />
                </div>
                <div
                  data-aos="fade-down"
                  data-aos-offset="200"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
                >
                  <img src={Ho3} alt="Rectangle31" />
                </div>
                <div
                  data-aos="fade-left"
                  data-aos-offset="200"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
                >
                  <img src={Ho4} alt="Rectangle31" />
                </div>
              </div>
            </div>
          </div>

          {/* <div className={singleHouse.holdApartments}>
            <div className={singleHouse.holdApartment2s}>
              <div
                className={singleHouse.holdBedrooms}
                data-aos="fade-right"
                data-aos-offset="200"
                data-aos-easing="ease-in-sine"
                data-aos-duration="600"
              >
                <h1>2 Bedroom Apartment </h1>
                <div>
                  <div>
                    <BsFillCheckCircleFill />
                  </div>
                  <p>
                    Lekki Pride I, 2 rooms apartment consists of two rooms and 1
                    Living rooms
                  </p>
                </div>
                <div>
                  <div>
                    <BsFillCheckCircleFill />
                  </div>
                  <p>
                    This gives you benefit to earn rental income from your
                    investment at lekki pride annually after full payment
                    completion or even earn daily, weekly and monthly from
                    shortlets /Airbnb.
                  </p>
                </div>
                <div>
                  <div>
                    <BsFillCheckCircleFill />
                  </div>
                  <p>
                    Lekki Pride is strategically located at Ajiwe Bus Stop, by
                    Abraham Adesanya Roundabout, Lekki-Epe Expressway.
                  </p>
                </div>
                <div>
                  <div>
                    <BsFillCheckCircleFill />
                  </div>
                  <p>
                    Lekki Pride 1 apartment offers spacious units of apartments
                    tailored to meet your highest standards.
                  </p>
                </div>
                <div>
                  <div>
                    <BsFillCheckCircleFill />
                  </div>
                  <p>
                    Abraham Adesanya estate is located after Ajah, off the Lekki
                    – Epe expressway. It is an estimated 30 minutes drive to
                    Victoria Island without traffic.
                  </p>
                </div>
              </div>
              <div
                className={singleHouse.holdBedroom2}
                data-aos="fade-left"
                data-aos-offset="200"
                data-aos-easing="ease-in-sine"
                data-aos-duration="600"
              >
                <img src={BED1} alt="Rectangle31" />
              </div>
            </div>
            <div className={singleHouse.holdSMallImages}>
              <div className={singleHouse.holdSMallImage2s}>
                <div
                  data-aos="fade-right"
                  data-aos-offset="200"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
                >
                  <img src={BED2} alt="Rectangle31" />
                </div>
                <div
                  data-aos="fade-down"
                  data-aos-offset="200"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
                >
                  <img src={BED3} alt="Rectangle31" />
                </div>
                <div
                  data-aos="fade-left"
                  data-aos-offset="200"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
                >
                  <img src={BED4} alt="Rectangle31" />
                </div>
              </div>
            </div>
          </div> */}

          {/* <div className={singleHouse.holdApartment}>
            <div className={singleHouse.holdApartment2}>
              <div
                className={singleHouse.holdBedroom}
                data-aos="fade-right"
                data-aos-offset="200"
                data-aos-easing="ease-in-sine"
                data-aos-duration="600"
              >
                <h1>
                  <span
                    style={{ width: "100%", padding: "20px" }}
                    className={singleHouse.holdBedroomSPan1}
                  >
                    3 Bedroom Apartment
                  </span>
                  <span className={singleHouse.holdBedroomSPan2}>N62M</span>
                </h1>
                <div>
                  <div>
                    <BsFillCheckCircleFill />
                  </div>
                  <p>
                    Lekki Pride I, 3 rooms apartment consists of three rooms and
                    one Living room
                  </p>
                </div>
                <div>
                  <div>
                    <BsFillCheckCircleFill />
                  </div>
                  <p>
                    This gives you benefit to earn rental income from your
                    investment at lekki pride annually after full payment
                    completion or even earn daily, weekly and monthly from
                    shortlets /Airbnb.
                  </p>
                </div>
                <div>
                  <div>
                    <BsFillCheckCircleFill />
                  </div>
                  <p>
                    Lekki Pride is strategically located at Ajiwe Bus Stop, by
                    Abraham Adesanya Roundabout, Lekki-Epe Expressway.
                  </p>
                </div>
                <div>
                  <div>
                    <BsFillCheckCircleFill />
                  </div>
                  <p>
                    Lekki Pride 1 apartment offers spacious units of apartments
                    tailored to meet your highest standards.
                  </p>
                </div>
                <div>
                  <div>
                    <BsFillCheckCircleFill />
                  </div>
                  <p>
                    Abraham Adesanya estate is located after Ajah, off the Lekki
                    – Epe expressway. It is an estimated 30 minutes drive to
                    Victoria Island without traffic.
                  </p>
                </div>
              </div>
              <div
                className={singleHouse.holdBedroom2}
                data-aos="fade-left"
                data-aos-offset="200"
                data-aos-easing="ease-in-sine"
                data-aos-duration="600"
              >
                <img src={HoTH2} alt="Rectangle31" />
              </div>
            </div>
            <div className={singleHouse.holdSMallImage}>
              <div className={singleHouse.holdSMallImage2}>
                <div
                  data-aos="fade-right"
                  data-aos-offset="200"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
                >
                  <img src={HoTH3} alt="Rectangle31" />
                </div>
                <div
                  data-aos="fade-down"
                  data-aos-offset="200"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
                >
                  <img src={HoTH4} alt="Rectangle31" />
                </div>
                <div
                  data-aos="fade-left"
                  data-aos-offset="200"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
                >
                  <img src={HoTH5} alt="Rectangle31" />
                </div>
              </div>
            </div>
          </div> */}

          <div className={singleHouse.holdLekkiPrideIEstate}>
            <div className={singleHouse.holdLekkiPrideIEstate2}>
              <h1
                data-aos="fade-down"
                data-aos-offset="200"
                data-aos-easing="ease-in-sine"
                data-aos-duration="600"
              >
                Lekki Pride I Estate
              </h1>
              <div>
                <p
                  data-aos="fade-down"
                  data-aos-offset="200"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
                >
                  Lekki Pride 1 apartment offers spacious units of apartments
                  tailored to meet your highest standards, with excellently
                  designed luxury homes, that brings comfort, luxury and
                  appreciation overtime.
                </p>
                <p
                  data-aos="fade-down"
                  data-aos-offset="200"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
                >
                  Lekki Pride Estate is strategically located at Ajiwe Bus Stop,
                  by Abraham Adesanya Roundabout, Lekki-Epe Expressway.
                </p>
                <p
                  data-aos="fade-down"
                  data-aos-offset="200"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
                >
                  Lekki Pride 1 estate is located after Ajah, off the Lekki –
                  Epe expressway. It is an estimated 30 minutes drive to
                  Victoria Island without traffic
                </p>
              </div>
              <h1
                data-aos="fade-down"
                data-aos-offset="200"
                data-aos-easing="ease-in-sine"
                data-aos-duration="600"
              >
                Proximity
              </h1>
              <div>
                <p
                  data-aos="fade-down"
                  data-aos-offset="200"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
                >
                  Eti-Osa Maternal And Child Care Center, Ajiwe Police Station
                  Agofure Motors, Conoil Filling Station , God is God Motor,
                  Park,Royal Garden Estate
                </p>
              </div>
              <h1
                data-aos="fade-down"
                data-aos-offset="200"
                data-aos-easing="ease-in-sine"
                data-aos-duration="600"
              >
                C of O
              </h1>
              <div>
                <p
                  data-aos="fade-down"
                  data-aos-offset="200"
                  data-aos-easing="ease-in-sine"
                  data-aos-duration="600"
                >
                  Initial Deposit Required- N20,000,000
                </p>
              </div>
              <h1
                data-aos="fade-down"
                data-aos-offset="200"
                data-aos-easing="ease-in-sine"
                data-aos-duration="600"
              >
                Selling Price
              </h1>
              <div
                data-aos="fade-down"
                data-aos-offset="200"
                data-aos-easing="ease-in-sine"
                data-aos-duration="600"
              >
                {/* <p>3 Bedroom Apartment – 46M</p>
                <p>2 Bedroom Apartment – 40M</p> */}
                <p>1 Bedroom Apartment – 50M</p>
              </div>
            </div>
          </div>
          <div
            className={singleHouse.holdLekkiPrideIEstateM}
            data-aos="fade-down"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <div className={singleHouse.holdLekkiPrideIEstate2M}>
              <h4>Make your Reservation</h4>
              <div className={singleHouse.holdLekkiPrideIEstate2M2}>
                <div className={singleHouse.holdForm}>
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="formGridAddress1">
                      <Form.Label>Full Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Full Name"
                        style={{ border: "2px solid #ffcc29" }}
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                        required
                      />
                    </Form.Group>
                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter email"
                          style={{ border: "2px solid #ffcc29" }}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </Form.Group>

                      <Form.Group as={Col} controlId="formGridPassword">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Phone Number"
                          style={{ border: "2px solid #ffcc29" }}
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          required
                        />
                      </Form.Group>
                    </Row>

                    <Form.Group
                      as={Col}
                      className="mb-3"
                      controlId="formGridState"
                    >
                      <Form.Label>How did you hear about us?</Form.Label>
                      <Form.Select
                        defaultValue=""
                        style={{ border: "2px solid #ffcc29" }}
                        value={social}
                        onChange={(e) => setSocial(e.target.value)}
                        required
                      >
                        <option></option>
                        <option>Linkedin</option>
                        <option>Instagram</option>
                        <option>Facebook</option>
                        <option>Twitter</option>
                      </Form.Select>
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      className="mb-3"
                      controlId="formGridState"
                    >
                      <Form.Label>Are you working with a Realtor</Form.Label>
                      <Form.Select
                        defaultValue=""
                        style={{ border: "2px solid #ffcc29" }}
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        required
                      >
                        <option></option>
                        <option>Yes</option>
                        <option>No</option>
                      </Form.Select>
                    </Form.Group>

                    <button className={singleHouse.formButton}>
                      {load ? "Please wait..." : "Submit"}
                    </button>
                  </Form>
                </div>
                <div className={singleHouse.holdMap}>
                  <iframe
                    id="iframeId"
                    title="Map"
                    className={singleHouse.mapKoko}
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
          <div
            className={singleHouse.holdAnytime}
            data-aos="fade-down"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <div>
              <h1 className={singleHouse.Reach}>You Can Reach Us Anytime</h1>
              <p className={singleHouse.question}>
                Have a question or doubt about our property?
              </p>
            </div>
            <button className={singleHouse.callUs}>
              <IoCall />
              Call us
            </button>
          </div>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h5>LEKKI PRIDE PHASE 1.</h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <iframe
              width="100%"
              height="300px"
              src="https://www.youtube.com/embed/DM1bbT_BYbc"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <SucceesModal show={modalShow} onHide={() => setModalShow(false)} />
      </section>
    );
}

export default SingleHouses;