import React, { useState, useEffect } from 'react';
import blogStyle from "./Blog1.module.scss";
import Rectangle123 from "../../../../image/Rectangle 123.png";
import arizonDetails3 from "../../../../image/arizonDetails3.png";
import arizonDetails4 from "../../../../image/arizonDetails4.png";
import HoTH4 from "../../../../image/HoTH4.png";

function Blog2(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <section className={blogStyle.holdALl}>
            <div>
                <div className={blogStyle.AllCategory}>
                    <div className={blogStyle.AllCategory2}>

                        <div>
                            <div>
                                <h3>Arizon Estate — Unlocking the Best Real Estate Investment in “The New Lagos”</h3>
                            </div>
                            <div>
                                <p className={blogStyle.Developtheskills}>
                                    Welcome to the world of <a href='https://arizonestate.com/' target="_blank" rel="noreferrer">Arizon Estate</a>, the ultimate investment opportunity within the thriving landscape of “The New Lagos.” In this blog post,
                                    we will explore why Arizon Estate stands out as the perfect investment option, offering unparalleled advantages to those seeking the best real estate
                                    property in this vibrant city.
                                </p>
                            </div>
                            <div className={blogStyle.HousingPublished}>
                                <span className={blogStyle.HousingPublished2}>Land</span>
                                <span className={blogStyle.HousingPublished3}>Published</span>
                            </div>
                            {/* <button className={blogStyle.learnMore}>Read More</button> */}
                            <div className={blogStyle.ADMINMARCH}>
                                <span>ADMIN. MARCH 20, 2023</span>
                            </div>
                        </div>
                    </div>
                    <div className={blogStyle.Rectangle123}>
                        <img src={arizonDetails3} alt="Rectangle123" />
                        <img src={arizonDetails4} alt="Rectangle123" />
                    </div>
                </div>


                <div className={blogStyle.AllCategory}>
                    <div className={blogStyle.AllCategory2}>
                        <div className={blogStyle.AllIbejuLekki1Pro}>
                            <div className={blogStyle.AllIbejuLekki}>
                                <h3>Prime Location within “The New Lagos”</h3>
                                <p>
                                    Discover the prime location of <a href='https://arizonestate.com/' target="_blank" rel="noreferrer">Arizon Estate</a> within the highly sought-after “The New Lagos” area.
                                    Situated near the Pan Atlantic University with proximity with <a href='https://www.dangoterefinery.org/' target="_blank" rel="noreferrer">Dangote Refinery</a> <a href='https://lfzdc.org/' target="_blank" rel="noreferrer">Lekki Trade Zone</a>, New International Airport, and Deep Sea Port. Arizon Estate enjoys exceptional
                                    accessibility to key business districts, transportation hubs, and economic opportunities in Lagos.
                                </p>
                            </div>
                            <div className={blogStyle.AllIbejuLekki}>
                                <h3>Unmatched Growth Potential</h3>
                                <p>
                                    Experience the unmatched growth potential of <a href='https://arizonestate.com/' target="_blank" rel="noreferrer">Arizon Estate</a> as part of the rapidly expanding
                                    real estate market in “The New Lagos.” This investment opportunity ensures maximized returns for investors,
                                    leveraging the continuous development and increasing demand for residential and commercial properties in the area.
                                </p>
                            </div>

                            <div className={blogStyle.AllIbejuLekki}>
                                <h3>World-Class Infrastructure and Amenities</h3>
                                <p>
                                    Explore the world-class infrastructure and abundant amenities offered by <a href='https://arizonestate.com/' target="_blank" rel="noreferrer">Arizon Estate</a>. From 24-hour security and perimeter fencing to recreational centers,
                                    nursery/daycare school, and a modern market, this estate caters to the diverse needs of residents and investors alike. Enjoy the convenience of a business center,
                                    estate club house, and serviced estate warehouse, enhancing the value of your investment.
                                </p>
                            </div>


                        </div>

                        <div className={blogStyle.AllIbejuLekki1Pro}>
                            <div className={blogStyle.AllIbejuLekki}>
                                <h3>Luxurious Living and Lifestyle</h3>
                                <p>
                                    Indulge in a luxurious living experience at <a href='https://arizonestate.com/' target="_blank" rel="noreferrer">Arizon Estate</a>. Embrace the convenience of a supermarket, estate clinic, cinema house, and ample parking space.
                                    With sports facilities, tennis courts, and a mini amusement park, <a href='https://arizonestate.com/' target="_blank" rel="noreferrer">Arizon Estate</a> provides recreational opportunities for residents to relax and enjoy a premium lifestyle.
                                </p>
                            </div>

                            <div className={blogStyle.AllIbejuLekki}>
                                <h3>Trusted Developers and Proven Track Record</h3>
                                <p>
                                    Invest with confidence in <a href='https://arizonestate.com/' target="_blank" rel="noreferrer">Arizon Estate</a>, developed by <a href='https://www.zylushomes.com/' target="_blank" rel="noreferrer">Zylus Homes</a> a trusted and reputable industry experts renowned for their commitment to quality and timely delivery.
                                    Benefit from their proven track record   <a href='https://nairametrics.com/2023/06/10/zylus-homes-launch-arizon-estate-to-help-over-1000-people-attain-home-ownership/' target="_blank" rel="noreferrer">here</a> of successful projects, ensuring that your investment aligns with your long-term financial goals and is supported by reliable professionals.
                                </p>
                            </div>

                            <div className={blogStyle.AllIbejuLekki}>
                                <h3>Conclusion</h3>
                                <p>
                                    <a href='https://arizonestate.com/' target="_blank" rel="noreferrer">Arizon Estate</a> represents the epitome of real estate investment in “The New Lagos.” Its prime location, unmatched
                                    growth potential, world-class infrastructure, and luxurious amenities make it the best real estate property to invest in.
                                    Unlock the full potential of your investment by securing a place in this thriving market, where quality, location, and promising growth converge.

                                    Don’t miss out on this exceptional opportunity to invest in <a href='https://arizonestate.com/' target="_blank" rel="noreferrer">Arizon Estate</a> and be a part of the transformation happening in “The New Lagos.”

                                    Experience the rewards of owning a property that combines unmatched quality, prime location, and promising growth. Start your journey toward
                                    a successful real estate investment in <a href='https://arizonestate.com/' target="_blank" rel="noreferrer">Arizon Estate</a> today. Let’s Discuss on 09069884912
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Blog2;