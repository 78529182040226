import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyA0Ur9jUXPqJmh97oWHec6iBzqOYIZ5pA0",
    authDomain: "zylushome.firebaseapp.com",
    projectId: "zylushome",
    storageBucket: "zylushome.appspot.com",
    messagingSenderId: "221527837615",
    appId: "1:221527837615:web:661a66a60a1dc0b1073d5a",
    measurementId: "G-BJ7N0C35WP"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
