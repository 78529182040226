module.exports = [
  {
    name: "Olumide Emmanuel",
    position: "Chairman, Board of Directors",
    member: "Zylus Group International",
    profile1:
      "Dr. Olumide Emmanuel is a visionary leader, an accomplished entrepreneur, and a renowned motivational speaker. With a passion for transforming lives and empowering individuals, he has become a beacon of inspiration to people around the world and a done in the Real Estate Industry.",

    profile2:
      "He is a leading relationship expert, motivational speaker, and wealth creation Agent with over 3 decades of impactful and transformational career journey. He is an influential personality in the spiritual and secular arena and a pioneer in many fields. ",
    profile3:
      "As a highly sought-after speaker and an overseer of over a network of churches in 4 continents; and founder of a group of businesses with a global footprint, Dr. Emmanuel has captivated audiences with his dynamic and engaging presentations. His ability to connect with people on a personal level, combined with his profound insights and practical wisdom, leaves a lasting impact on everyone who has the privilege of hearing him speak.",

    profile4:
      "Dr. Olumide's journey to success is nothing short of remarkable. Born and raised in Nigeria, he overcame numerous challenges and adversities to achieve remarkable feats. His unwavering determination, coupled with his unyielding belief in the potential of the human spirit, enabled him to rise above his circumstances and create a life of purpose and abundance.",
    profile5:
      "He is an alumnus of University of Huddersfield (U.K), Havard Business School (USA) – where he studied: Executive Masters Certificate in Real Estate with special focus on Capital Market, Project Finance and Business Strategy. He is presently running his Doctoral Program at Logos University (USA)",
    profile6:
      "As the founder and CEO of many successful Business conglomerate, Dr. Olumide has built an empire that spans various industries, including Religion, finance, real estate, and media. His entrepreneurial acumen and strategic thinking have not only yielded significant financial success but have also provided countless employment opportunities for individuals seeking to improve their livelihoods. ",
    profile7:
      "Beyond his business ventures, Dr. Emmanuel is deeply committed to philanthropy and giving back to society through – Olumide Emmanuel Foundation and The Entrepreneurship Academy. He actively supports numerous charitable organizations, focusing on education, healthcare, and poverty alleviation. His philanthropic efforts have touched the lives of countless individuals, enabling them to access essential resources and unlock their full potential with focus on",
    profile8:
      "Dr. Olumide Emmanuel is also an accomplished author, with several bestselling books to his name. Through his writing, he shares his wisdom, experiences, and strategies for personal and professional growth. His books have inspired and empowered readers to overcome obstacles, unleash their creativity, and achieve extraordinary success. He is called “The Common Sense Guru” by many of his protégé. Among his books are: School Of Money, The Common Sense, Rule Of Money, breaking the stronghold of delay, maximizing Opportunities etc.",
    profile9:
      "Recognized for his outstanding contributions to society, Dr. Emmanuel has received numerous accolades and awards. His impact has been acknowledged globally, and he has been invited to speak at prestigious conferences and events in over 16 countries in Africa, Asia, Europe, America (North & South).",
    profile10:
      "As a mentor and coach, Dr. Olumide has guided and mentored individuals from all walks of life, helping them discover their true purpose and reach their full potential. His mentoring programs and workshops have equipped countless individuals with the tools and knowledge needed to succeed in their personal and professional endeavors.",
    profile11:
      "He presently seats in the board of 7 companies both in Nigeria, Kenya, Rwanda, The United Arab Emirate, United State Of America and Canada.",
    profile12: "",
    image: require("../image/Team1Pro.png"),
  },
  {
    name: "Dr Olatujoye Oluwatosin",
    position: "Group Chief Executive Officer",
    member: "Zylus Group International",
    image: require("../image/Team1.png"),
    profile1:
      "Dr. Oluwatosin Olatujoye has over a decade of Sales and Marketing, Negotiation, Public Speaking, Management, Human Resource; Project Management and Development, sales and marketing experience. He started his career as a sales representative at American International Insurance Company (AIICO) in 2007 where began his journey and rose up the ladder to become a branch manager.",
    profile2:
      "Over the years, he has acquired skills that have been instrumental to the growth of his career, being an astute entrepreneur, he carried out various researches to learn about the Real Estate industry and came up with solutions to solving the housing deficit problems in Nigeria.Hence, he founded Zylus Group International.Among numerous educational qualifications, Dr.Oluwatosin Olatujoye is an alumnus of the School of Estate, where he learned the fundamental and comprehensive aspects of Real Estate. He earned himself a certificate in entrepreneurship from the Metropolitan School of Business and Management, UK.Dr.Oluwatosin Olatujoye is a fellow of the Institute of Credit Administration.",
    profile3:
      "He has attended and participated in numerous local and international seminars and summits in his quest for greatness. He is a member of several professional bodies including the Project Management Professional Institute(PMPI), International Strategic Management Institute(ISMI)- UK.Over time, he has acquired several certifications, among which are; Professional Post - graduate Certificate in Real Estate & Asset Valuation Management, Proficiency in Human Resources Management, Certified International Strategic Management(CISM), Proficiency in International Strategic Management(PISM) and Certificate in Entrepreneur Management(CEM), by Enterprise Development Center, PAN Atlantic University & Lagos Business School.",
    profile4:
      "As a humanitarian, he has a keen interest in giving back to society; raising help for the needy and youth capacity development.Under his leadership, Zylus Group International, and his foundation - Oluwatosin Olatujoye Foundation carried out several Community Social Responsibility initiatives during the first wave of the COVID - 19 pandemic, and has continued to provide help to the needy since then with its FEED THE 1000 initiative among other initiatives.",
    profile5:
      "In recognition of his contribution to the Real Estate industry and the society at large, he has won prestigious awards like; AIICO's Best Unit Manager (2016), AIICO Top 10 Agency Manager in Revenue (2017), Nigeria Achievers Award (2020), Top 50 Entrepreneurs in Nigeria, Icon of Humanity Award, and Nigeria Property Merit Awards. He was recently named he was named the West Africa Man of the Year by ECOWAS Youth Council and honored with the Nelson Mandela Award for Excellence by the same organization.",
    profile6: "",
    profile7: "",
    profile8: "",
    profile9: "",
    profile10: "",
    profile11: "",
    profile12: "",
  },
  {
    name: "Bukola Olatujoye",
    position: "Chief Executive Officer",
    member: "Zylus Homes & Properties",
    image: require("../image/Team2.jpg"),
    profile1:
      "Bukola Olatujoye is a seasoned and experienced business administrator and entrepreneur with over a decade of practical experience.",
    profile2:
      "She is a graduate of Business Administration from Lagos State University(LASU).She had served as a senior member of top organizations.",
    profile3:
      "Bukola Olatujoye is certified Human Resources Management Professional.Currently, she serves at the helm of affairs of the management team at Zylus, she has helped steer the organization's affairs towards greater heights and achieve success, which is evident in the company's revenue within the last two years.",
    profile4:
      "At the helm of management affairs in Zylus where she currently occupy the position of the Chief Executive Officer, Mrs Olatujoye was recently recognized and honour by the Nigeria Achievers Award as the Distinguished Real Estate Practitioner of year 2021.",
    profile5: "",
    profile6: "",
    profile7: "",
    profile8: "",
    profile9: "",
    profile10: "",
    profile11: "",
    profile12: "",
  },
  {
    name: "Lateef Lawal",
    position: "ED, Real Estate & Corporate Service",
    member: "Zylus Group International",
    image: require("../image/Team3.jpg"),
    profile1:
      "Lateef Started his career in the banking sector where he held the position of a Customer service.",
    profile2:
      " During his stay in Stanbic IBTC Bank he was able manage thousands of diversed customers.",
    profile3:
      "While in the Bank he also shuffle the role of Personal Banking Role which strictly for sales of accounts opening.",
    profile4:
      "After some few years in Banking industry he transited to Oil and Gas sector as a Station Manager.",
    profile5:
      "Lateef exhibits his Human relations Skills coupled with his sales and Marketing experience in the bank.",
    profile6:
      "He learned procurement and finance of petroleum products. After some fews years in the Oil and Gas industry he moved to the Real estate industry.",
    profile7:
      "He joined a startup real estate company called OGA FOR PROPERTY INVESTMENT LTD now Zylus Group International.Upon joining the company he fully displayed all his experiences over the years and rose from being a HR officer to Executive Director Human Capital, Corporate Services and Real Estate. He has managed over 1000 stakeholders and staff members prior to joining Zylus Group International(OGA FOR PROPERTY INVESTMENT LTD).",
    profile8:
      "He joined the company with little or no Real Estate Consultant and as we speak he is managing over 4000 Real Estate Consultant(Realtors) and invariably 65 staff members. Lateef Lawal holds a Bachelor of Science Degree in Industrial Relations and Personnel Management from Lagos State University, Ojo Lagos State.Lateef is currently a MASTERS OF BUSINESS ADMINISTRATION(MBA- HRM) student of BABCOCK UNIVERSITY, Ilishan Remo, ogun State. He is a Member of the Nigerian Institute of Management(Chartered) as well as an Full Member, Chartered Institute of Human Resources Management Nigeria(CIHRMN) He has Profiency Certificate in Management.He is also bagged a Post Graduate Diploma in International Digital Marketing College UK.He is a Certified Sales and Marketing Analyst and also an Associate Member Digital and Social Media Marketing from the International Digital Marketing College UK.",
    profile9:
      "Lateef has also attended Leadership and Strategy classes with Popular Pastor Niyi Adesanya, Northcourt and the Billionaire Conclave(Pastor Olumide Emmanuel) Mr.Lawal L O.PGD.DM, MCIHRM, AIDMC, UK; CPM, CSMA, MBA(in view)",
    profile10: "",
    profile11: "",
    profile12: "",
  },
  {
    name: "Wasiu Sonekan",
    position: "ED, Finance & Investment",
    member: "Zylus Group International",
    image: require("../image/Team4.png"),
    profile1:
      "Wasiu Sonekan is a highly motivated individual, an accomplished, versatile, and result-driven professional with a proven track record in accounting and office administration, ultimately benefitting the organization's bottom line. Aside from his position in the cooperative, He is the Managing Director of Zylus Investment and since he joined the organization, he has pushed the Investment arm to achieve giant strides.",
    profile2:
      "He is a graduate of Accounting from the Federal University of Agriculture, Abeokuta(FUNAAB) Ogun State, Nigeria, and an Associate member of the Chartered Institute of Management Accountants of Nigeria.",
    profile3: "",
    profile4: "",
    profile5: "",
    profile6: "",
    profile7: "",
    profile8: "",
    profile9: "",
    profile10: "",
    profile11: "",
    profile12: "",
  },
  {
    name: "Gbenga Ojo",
    position: "GMD/COO",
    member: "Zylus Group International",
    image: require("../image/Team5.png"),
    profile1:
      "Olugbenga Ojo has over 7 years experience as MD/CEO, 5 years as a General Manager/HBO and other Senior Management positions. ",
    profile2:
      "He is an experienced corporate and business process re- engineer who has demonstrated the ability to lead diverse teams of professionals to new levels of success in a variety of highly competitive industries, cutting - edge markets and fast paced environments.",
    profile3:
      "He possesses a strong technical, professional, and business qualifications with an impressive track record of more than Eighteen(18) years hands - on - field experience in Strategic and Corporate Planning, Business Unit Development, Product and Project Management, Business Process reengineering strategies and growth cum people oriented management and has led very efficient organizations and departments, increasing their market share, profitability, reducing costs and enhancing business operations He holds M.Sc.and MBA degrees in Industrial Chemistry and Marketing respectively, a Miller Heiman Certified Sales Professional in Strategic and Conceptual Selling; Project Management Professional(PMP), Scrum Master Certified, Fellow & Head of Faculty, Chartered Institute of Project Management; Fellow & Head of Faculty, Institute of Customer Relationship Management.A member of NIPR, ScrumStudy™ USA and many other professional bodies.",
    profile4:
      "He was the Managing Director of Rescue Technologies Ltd, Chief Executive Officer of Poise Nigeria, Group Business Director / COO of Chain Reactions Nigeria Ltd, and Chief Executive Officer of Emirates Nigeria 247 Ltd before joining Zylus Elite Cooperative Thrift & Credit Society Ltd as the Vice President.",
    profile5: "",
    profile6: "",
    profile7: "",
    profile8: "",
    profile9: "",
    profile10: "",
    profile11: "",
    profile12: "",
  },
  {
    name: "Adeleke Hafeez",
    position: "Non- Executive Director",
    member: "Zylus Group International",
    image: require("../image/Team6.png"),
    profile1:
      "Hafees Adeleke is a finance expert with over two decades of experience in Accounting and Auditing.",
    profile2:
      "He started his career in 1998 with Co-Link Investment, where he began his journey on the road to building an outstanding career for himself.",
    profile3:
      "Thereafter, he proceeded to the Federal University of Agriculture, Abeokuta in 2008 and rose through the ranks to become Principal Internal Auditor in 2018 at the Directorate of Internal Audit.",
    profile4:
      "Hafees Adeleke is an Associate Member of the Association of Accounting Technicians(AAT), and a Fellow of the Institute of Chartered Accountants of Nigeria(ICAN).",
    profile5: "",
    profile6: "",
    profile7: "",
    profile8: "",
    profile9: "",
    profile10: "",
    profile11: "",
    profile12: "",
  },
  {
    name: "Abiye Tam - George",
    position: "Non- Executive Director",
    member: "Zylus Group International",
    image: require("../image/Team7.png"),
    profile1:
      "Abiye Tam-George is an accredited Regional Attorney-Mediator with the Compliance Advisor Ombudsman (CAO) for the International Financial Corporation (IFC) and the Multilateral Investment Guarantee Agency (MIGA) of the World Bank Group.",
    profile2:
      "She is a certified Mediator with the Lagos State Multi- Door Courthouse.A member distinguished panel of Neutrals LMDC.Abiye has a PGD in Peace Studies and Conflict Resolution.",
    profile3:
      "She is an astute mediation advocate and a motivational speaker. Her love for education has made her pioneer different seminars and workshops on educational advancement.",
    profile4:
      "She is also a member of the Board of Trustees; Lawyers in Oil and Gas Network, Association of Environmental Lawyers of Nigeria, and City of Peace Initiative Lagos.",
    profile5: "",
    profile6: "",
    profile7: "",
    profile8: "",
    profile9: "",
    profile10: "",
    profile11: "",
    profile12: "",
  },
  {
    name: "Engr Steven Ajayi-James",
    // position: "",
    position: "Non- Executive Director",
    member: "Zylus Group International",
    image: require("../image/Team8.png"),
    profile1:
      "MNICE – Managing Director at Folaven Limited: Steven is the Managing Director of Folaven Limited (A project management and construction firm).",
    profile2:
      "Prior to this, He was the Construction Project Manager at Realty Point Limited between 2015 and 2021. Steven has also been a facilitator at the School of Real Estate and Business since 2016.",
    profile3:
      "He worked as an Assistant manager at COTECNA Destination Inspection Limited and Crown Realties Plc prior to this.",
    profile4:
      "Steven is a Civil Engineer and a Certified Project Manager with a wide range of experience on civil engineering projects, real estate and project management field. He is a graduate of Civil Engineering from Ladoke Akintola University of Technology, Ogbomoso.",
    profile5:
      "He also has a Masters in Project Management(MPM) from the University of Lagos. He is a COREN registered Engineer, a Certified Project Management Professional(PMP) by the Project Management Institute, a Corporate member of Nigerian Society of Engineers(NSE) and a member of Nigerian Institute of Civil Engineers(NICE).",
    profile6: "",
    profile7: "",
    profile8: "",
    profile9: "",
    profile10: "",
    profile11: "",
    profile12: "",
  },
  {
    name: "Barr Kayode Omosehin",
    // position: "",
    position: "Non- Executive Director",
    member: "Zylus Group International",
    image: require("../image/Team22.png"),
    profile1: "Not Available",
    profile2: "",
    profile3: "",
    profile4: "",
    profile5: "",
    profile6: "",
    profile7: "",
    profile8: "",
    profile9: "",
    profile10: "",
    profile11: "",
    profile12: "",
  },
  {
    name: "Ijeoma Theo- Nwangwu",
    position: "Non Executive Director",
    member: "Zylus Group International",
    image: require("../image/Ijeoma Theo- Nwangwu.jpg"),
    profile1:
      "Ijeoma Theo-Nwangwu is a marketing expert with experience spanning over two decades. She is a certified high ticket closer. She has expertise in trade and marketing strategies with an emphasis on real estate and renewable energy. She is dependable and analytical in her approach towards robust marketing solutions. ",
    profile2:
      "She is a graduate of English/Literature from Abia State University and an MBA from Delta State University with certifications from academies of interest. Ijeoma is a board member of a few Real Estate companies and holds leadership positions in various professional associations and social clubs",
    profile3: "",
    profile4: "",
    profile5: "",
    profile6: "",
    profile7: "",
    profile8: "",
    profile9: "",
    profile10: "",
    profile11: "",
    profile12: "",
  },
  {
    name: "Onyemaechi Harris B.,AICMC",
    position: "Solicitor/Legal advisor",
    member: "Zylus Group International",
    image: require("../image/bar.jpg"),
    profile1: "Not Available",
    profile2: "",
    profile3: "",
    profile4: "",
    profile5: "",
    profile6: "",
    profile7: "",
    profile8: "",
    profile9: "",
    profile10: "",
    profile11: "",
    profile12: "",
  },
  {
    name: "Aderemi Adekunle",
    position: "Human Resources Management",
    member: "Zylus Group International",
    image: require("../image/Team23.png"),
    profile1:
      "Remi Adekunle, the Managing Partner of Dapsadek Nigeria & Co. Ltd. (Dapsadek Consulting) is a passionate human resource practitioner with 23 years leadership experience, and specialities in Organizational Development and Change Management. She is a Coach, Trainer, and sought-after Speaker at Management Retreat and Conferences.",
    profile2:
      "She holds a master’s degree in Industrial Relations and Labour Management. She is a certified Senior Professional in Human Resources-International (SPHRi). She is also a full Member of the Chartered Institute of Personnel Management of Nigeria, and the Nigeria Institute of Training and Development. ‘Remi has attended many courses both locally and internationally in Management, Organizational Development, and Leadership.",
    profile3:
      "She integrates business insights with organizational development strategies to create human resources solutions that help businesses accomplish ambitious goals. In the various organisations where she worked as Head of Human Resources, before starting her consulting practice: Lasaco Assurance Plc., De Tastee Fried Chicken Ltd., and Scib Nigeria & Co. Ltd. She put in place sustainable HR systems and structures and deployed Human Resources strategies that fast-tracked their growth.",
    profile4:
      "At present, she works collaboratively with organisational leaders, C-Suite teams, and HR departments, to create strategies and actionable plans that effectively support the realization of their business objectives.",
    profile5: "",
    profile6: "",
    profile7: "",
    profile8: "",
    profile9: "",
    profile10: "",
    profile11: "",
    profile12: "",
  },
];
