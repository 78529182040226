import React, { useEffect } from 'react';
import who from "./WhoAreWe.module.scss"
import { ImQuotesLeft } from 'react-icons/im';
import roll1 from "../../../image/rolling1.png";
import roll2 from "../../../image/rolling2.png";
import gray1 from "../../../image/gray1.png";
import gray2 from "../../../image/gray2.png";
import gray3 from "../../../image/gray3.png";
import gray4 from "../../../image/gray4.png";
import loyalty from "../../../image/loyalty.png";
import loyalty2 from "../../../image/loyalty 2.png";
import loyalty3 from "../../../image/loyalty 3.png";
import hulkhouse from "../../../image/hulkhouse.png";

function WhoAreWe(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
      <section>
        <div className={who.Layer1}>
          <div className={who.Layer1Pro1}>
            <div
              className={who.Layer1Pro1A1}
              data-aos="fade-right"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
            >
              <h1>WHO WE ARE</h1>
              <p>
                Zylus Homes is a cutting-edge real estate and investment
                company, dedicated to increasing access to homes and property
                ownership in Africa.
              </p>
              <p>
                Our research has made us realise that buying and selling real
                estate is presumed to have so many tasking procedures. Hence,
                this is the rationale behind keeping our approach as simple and
                straightforward as possible. In this regard, we empower people
                to make smarter decisions concerning buying, developing their
                home or investing in our properties. We have, over the years,
                built credibility and are known for offering excellent service
                delivery.
              </p>
              <p>
                Our testimonials and success stories have been featured in
                reputable newspapers such as Punch, Vanguard, Business Day,
                among many others. We exist to put a smile on our customers’
                faces!
              </p>
            </div>
            <div
              className={who.Layer1Pro2}
              data-aos="fade-left"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
            >
              <div className={who.Layer1Pro2A}>
                <p className={who.Layer1Pro2A1}>
                  <ImQuotesLeft />
                </p>
                <div className={who.Layer1Pro2A2}></div>
                <p className={who.Layer1Pro2A3}>
                  “We believe that it takes great people to deliver a great
                  product”
                </p>
                <p className={who.Layer1Pro2A4}>The Zylus Team</p>
              </div>
            </div>
          </div>
        </div>
        <div className={who.Layer2}>
          <marquee
            behavior="alternate"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <div className={who.holdImageBankStone}>
              <div>
                <img src={roll1} alt="banks" className={who.holdImageKoMeB} />
              </div>
              <div>
                <img src={roll2} alt="banks" className={who.holdImageKoMeB} />
              </div>
              <div>
                <img src={roll1} alt="banks" className={who.holdImageKoMeB} />
              </div>
              <div>
                <img src={roll2} alt="banks" className={who.holdImageKoMeB} />
              </div>
              <div>
                <img src={roll1} alt="banks" className={who.holdImageKoMeB} />
              </div>
              <div>
                <img src={roll2} alt="banks" className={who.holdImageKoMeB} />
              </div>
              <div>
                <img src={roll1} alt="banks" className={who.holdImageKoMeB} />
              </div>
              <div>
                <img src={roll2} alt="banks" className={who.holdImageKoMeB} />
              </div>
              <div>
                <img src={roll1} alt="banks" className={who.holdImageKoMeB} />
              </div>
            </div>
          </marquee>
        </div>
        <div className={who.Layer3}>
          <div
            className={who.Layer3Pro}
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-duration="600"
          >
            <div className={who.Layer3Pros}>
              <div className={who.Layer3Pro1}>
                <h1>TRUSTED BY THE WORLD’S MOST RENOWNED COMPANIES.</h1>
              </div>
              <p>
                Over 500 companies across different countries have transacted
                with us.
              </p>
              <div className={who.holdgray1Pic}>
                <div>
                  <img src={gray1} alt="banks" className={who.holdgray1} />
                </div>
                <div>
                  <img src={gray2} alt="banks" className={who.holdgray2} />
                </div>
                <div>
                  <img src={gray3} alt="banks" className={who.holdgray3} />
                </div>
                <div>
                  <img src={gray4} alt="banks" className={who.holdgray4} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={who.Layer4}
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
        >
          <div className={who.Layer4Pro}>
            <div className={who.holdgray4loyalty3}>
              <img src={hulkhouse} alt="banks" />
            </div>
            <div className={who.Layer4Pro1}>
              <div className={who.Layer4Pro2}>
                <img src={loyalty} alt="banks" className={who.holdgray4} />
                <h4>OUR CORE VALUES</h4>
                <ul>
                  <li>Passion for excellence</li>
                  <li>Accountability</li>
                  <li>Collaboration</li>
                  <li>Integrity</li>
                  <li>Learning</li>
                  <li>Innovation</li>
                </ul>
              </div>
              <div className={who.Layer4Pro2 + " " + who.lineMinkMe}>
                <img src={loyalty2} alt="banks" className={who.holdgray4} />
                <h4>Our Mission</h4>
                <p>
                  To support individuals and organisations in achieving
                  financial sustainability and creating value for our
                  stakeholders, through innovative and technology.
                </p>
              </div>
              <div className={who.Layer4Pro2}>
                <img src={loyalty3} alt="banks" className={who.holdgray4} />
                <h4>Our Vision</h4>
                <p>
                  To be a leading global conglomerate, offering financial
                  sustainable solutions, for the betterment of humanity;
                  Leveraging innovation and cutting-edge technology. PAY-OFF
                  LINE: Nurturing Dreams, Building Reality
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className={who.Layer5}
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
        >
          <div className={who.Layer5Pro}>
            <div className={who.Layer1Pro2}>
              <div className={who.Layer1Pro2A}>
                <p className={who.Layer1Pro2A1}>
                  <ImQuotesLeft />
                </p>
                <div className={who.Layer1Pro2A2}></div>
                <p className={who.Layer1Pro2A3}>
                  “We believe that it takes great people to deliver a great
                  product”
                </p>
                <p className={who.Layer1Pro2A4}>The Zylus Team</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}

export default WhoAreWe;